import axios from 'axios'
import { ElMessage } from 'element-plus'
axios.defaults.headers["Content-Type"] = "application/json";
import Rsa from "@/utils/rsautil.js"
import Aes from "@/utils/aesutil.js"
class Request{


    TIMEOUT = 50000

    constructor(){
        this.instance = axios.create({
            baseURL: "/api",
            timeout: this.TIMEOUT,
        })
        this.init_interceptors_request()
        this.init_interceptors_response()
    }

    // 请求拦截器
    init_interceptors_request(){
        this.instance.interceptors.request.use(config => {
	
			if (config.method === 'get'  && config.params) {
				Object.keys(config.params).forEach(key => {
				  let value = config.params[key];
				  if (typeof value === 'number') {
					// console.log(value)
				    value = value.toString(); // 将数字转换为字符串
					// console.log(value)
				  }
				   if (typeof value === 'undefined') {
					   value = ""
				   }
				//   console.log(value)
				  config.params[key] = Rsa.encryptText(value); 
				//   console.log(value)
				});
			    } else if (config.method === 'post'  && config.params) {
			      // 对post请求的参数进行加密
			      // config.data = encryptParams(config.data)
			    }
				
			// // console.log(config)	
            return config
          }, error => {

            return Promise.error(error)
          }
        )
    }

    // 返回拦截器
    init_interceptors_response(){
        this.instance.interceptors.response.use(response => {
            // let encryptedData = response.data;
   //          // console.log(encryptedData);
			let secretKey = response.headers.get('SecretKey');
			if (!window.location.href.includes('/DataAnalyse/message') && !window.location.href.includes('pushMsg')) {
			    try {
			        let mimi = Rsa.decryptText(secretKey);
			        let decryptedData = this.traverseAndDecrypt(response.data, mimi);
			        let jsonObj = JSON.parse(decryptedData);
			        response.data = jsonObj;
			    } catch (error) {
			        console.log("解密失败");
			    }
			}

            return response;
        }, error => {
            ElMessage({
                type: 'error',
                message: error.message
            });
            return Promise.reject(error);
        });
    }
    
    traverseAndDecrypt(obj,mykey) {
		
        if (typeof obj === 'object') {
            // 如果数据是对象，则遍历并递归解密
            Object.keys(obj).forEach(key => {
                obj[key] = this.traverseAndDecrypt(obj[key],mykey);
            });
            return obj;
        } else if (typeof obj === 'string') {
            // 如果数据是字符串，则进行解密操作
            // return Rsa.decryptText(obj);
			return Aes.decrypt(obj.replace(/\r\n/g,"").replace(/\n/g,""),mykey);
        }else {
            // 其他情况，不做处理，直接返回
            return obj;
        }
		
		
    }
	encryptParams(params) {
	  // 对参数值进行加密的逻辑
	  for (const key in params) {
	    if (params.hasOwnProperty(key)) {
	      params[key] = encryptValue(params[key]);
	    }
	  }
	  return params;
	}
	
	// 加密参数值的方法
	encryptValue(value) {
	  encryptedValue = Rsa.encryptText(value)
	  return encryptedValue;
	}

    data_processing(config){
    //   code
      return config
    // return {
    //     method:config.method,
    //     url:config.url,
    //     data:config.method === 'POST' || config.method === 'PUT'?config.params:null,
    //     params:config.method === 'get' || config.method === 'delete'?config.params:null,

    // }
    }

    request(config){
        this.data_processing(config)

        return this.instance(this.data_processing(config))
    }

}

const request = new Request()

export default request;
