import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
// import MainView from '../views/MainView.vue'
const innerWidth = window.innerWidth
const routes = [
  {
    path: "/",
    name: 'index',
    redirect: innerWidth>750?'/en/home':'/en/moveHome',
    meta: {
      title: "Falcon",
      type: "index"
    }
  },
  {
    path: "/en",
    name: "n1",
    redirect:innerWidth>750?'/en/home':'/en/moveHome',
    meta: {
      title: "Falcon",
      type: "n1"
    }
  },
  {
    path: "/ch",
    name: "n2",
    redirect: innerWidth>750?'/ch/home':'/ch/moveHome',
    meta: {
      title: "Falcon",
      type: "n2"
    }
  },
  {
    /// 首页
    path: "/:zh_en/home",
    name: 'main',
    // component: MainView,
    component: () => import("@/views/MainView.vue"),
    props: true,
    meta: {
      title: "Falcon",
      type: "main"
    }
  },
  {
    /// 移动端首页
    path: "/:zh_en/moveHome",
    name: 'moveMain',
    component: () => import("@/views/MoveMain.vue"),
    props: true,
    meta: {
      title: "Falcon",
      type: "moveMain"
    }
  },
  {
    /// 资产追踪器
    path: "/:zh_en/asset_tracker/home",
    name: 'home',
    // component: HomeView,
    component: () => import("@/views/HomeView.vue"),
    props: true,
    meta: {
      title: "Falcon",
      type: "home"
    }

  },
  {
    /// 多因子选板块
    path: "/:zh_en/multi_factor_category_selector",
    name: 'selectTrack',
    component: () => import("@/views/SelectTrack.vue"),
    props: true,
    meta: {
      title: "Falcon",
      type: "selectTrack",
      keepAlive: true // 标记列表页需要被缓存
    }
  },
  {
    /// 多因子选板块详情页面
    path: "/:zh_en/multi_factor_category_selector/:categoryName/:dataSource",
    name: 'selectTrackDetail',
    component: () => import("@/views/SelectTrackDetail.vue"),
    props: true,
    meta: {
      title: "Falcon",
      type: "selectTrackDetail"
    }
  },
  {

    path: "/:zh_en/asset_tracker/:coin/:type",
    name: 'menu',
    component: () => import("@/views/MenuView.vue"),
    props: true,
    meta: {
      title: "Falcon",
      type: "menu"
    }
  },
  {
    // 忘记密码
    path: '/:zh_en/foregetPwd',// path，需要传入params为zh_en的参数
    name: 'foregetPwd',// 路由名称
    component: () => import("@/views/ForegetPWD.vue"),// 路由组件，使用import实现路由懒加载
    meta: { // 路由元信息,必须携带title为"Falcon"，以及以当前路由的名称为值的type
      title: "Falcon",
      type: "foregetPwd"
    }
  },
  {
    path: '/:zh_en/login',
    name: 'login',
    // component: () => import("@/views/LoginView.vue"),
    component: LoginView,
    meta: {
      title: "Falcon",
      type: "login"
    }
  },
  {
    path: '/:zh_en/register',
    name: 'register',
    // component: () => import("@/views/LoginView.vue"),
    component: LoginView,
    meta: {
      title: "Falcon",
      type: "register"
    }
  },
  {
    path: '/:zh_en/agreement',
    name: 'agreement',
    component: () => import("@/views/Agreement.vue"),
    meta: {
      title: "Falcon",
      type: "agreement"
    }
  },
  {
    /// 短线交易工具
    path: '/:zh_en/short_term_trading_tool',
    name: 'shortTermTradingTool',
    props: true,
    component: () => import("@/views/ShortTermTradingTool.vue"),
    meta: {
      title: "Falcon",
      type: "shortTermTradingTool"
    }
  },
  {
    /// 多因子选币器
    path: '/:zh_en/selectCurrency',
    name: 'selectCurrency',
    props: true,
    component: () => import("@/views/SelectCurrency.vue"),
    meta: {
      title: "Falcon",
      type: "selectCurrency"
    }
  },
  {
    // 多因子选币器-详情页
    path: '/:zh_en/selectCurrency/selectCoin/strategyDetails',
    name: 'strategyDetails',
    props: true,
    component: () => import("@/components/selectCoin/strategyDetails.vue"),
    meta: {
      title: "Falcon",
      type: "strategyDetails"
    }
  },
  {
    /// 宏观投资分析工具
    path: '/:zh_en/macroscopic_investment_investment_tool/:tab',
    name: 'macroscopicInvestment',
    props: true,
    component: () => import("@/views/MacroscopicInvestment.vue"),
    meta: {
      title: "Falcon",
      type: "macroscopicInvestment"
    }
  },
  {
    /// 风险提示器
    path: '/:zh_en/riskReminder/:tab',
    name: 'riskReminder',
    props: true,
    component: () => import("@/views/RiskReminder.vue"),
    meta: {
      title: "Falcon",
      type: "riskReminder"
    }
  },
  {
    /// 消息管理
    path: '/:zh_en/messageManagement',
    name: 'messageManagement',
    props: true,
    component: () => import("@/views/messageManagement.vue"),
    meta: {
      title: "Falcon",
      type: "messageManagement"
    }
  },
  {
    /// 个人中心
    path: '/:zh_en/personalCenter',
    name: 'personalCenter',
    props: true,
    component: () => import("@/views/personalCenter.vue"),
    meta: {
      title: "Falcon",
      type: "personalCenter"
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
function getFiles() {
  let res = {};
  require
    .context("@/assets/icon/svg", true)
    .keys()
    .map((item) => {
      res[item.replace("./", "")] = require("@/assets/icon/svg/" +
        item.replace("./", ""));
    });
  return res;
}

router.beforeEach((to, from, next) => {
    // window.document.title = to.meta.title
    console.log('来自这里：',from)
    console.log('要到这里：',to)
    //   if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
      
    // }
    // const uid = document.cookie.replace(/(?:(?:^|.*;\s*)uid\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    //   const email = document.cookie.replace(/(?:(?:^|.*;\s*)email\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    //   console.log('document.cookie:',uid)
    //   console.log('document.cookie:',email)
    // const uid = document.cookie.replace(/(?:(?:^|.*;\s*)uid\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    //   const email = document.cookie.replace(/(?:(?:^|.*;\s*)email\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    //   console.log('cookie-uid:',uid)
    //     console.log('cookie-email:',email)
  if ((to.query.uid && JSON.parse(sessionStorage.getItem('userInfo')) && JSON.parse(sessionStorage.getItem('userInfo')).uid == Number(to.query.uid)) || (!to.query.uid && !JSON.parse(sessionStorage.getItem('userInfo')))) {
    console.log('to:',to)
    // const isAsset_tracker = to.path.indexOf('asset_tracker')!=-1?true:false
    // const isHome = to.path.indexOf('home')!=-1?true:false
    if(to.name=='menu'&&!JSON.parse(sessionStorage.getItem('search_key'))){
      console.log('fuhe')
      window.sessionStorage.setItem('search_key',JSON.stringify({}))
      next({name:'menu',query: to.query,params:to.params})
    }else{
      console.log('bufuhe')
      next()
    }
    // next()
      // if(to.params.coin&&to.params.coin!=String(JSON.parse(sessionStorage.getItem('search_key')).falconId)){
      //   const params = to.params
      //   // params.type = 'overview'
      //   next()
      // }else{
      //   next()
      // }
        
  } else if (to.query.uid && JSON.parse(sessionStorage.getItem('userInfo')) && JSON.parse(sessionStorage.getItem('userInfo')).uid != Number(to.query.uid)) {
    sessionStorage.removeItem('userInfo')
    next({ name: 'login', query: { from: to.name }, params: to.params })
  } else if (!to.query.uid && JSON.parse(sessionStorage.getItem('userInfo'))){
    const uid = JSON.parse(sessionStorage.getItem('userInfo')).uid
    const query1 = to.query
    query1.uid = uid
    const query2 = query1
    next({ name: to.name, query: query2, params: to.params })
  }
  else {
    // if (JSON.parse(sessionStorage.getItem('userInfo'))) {
    //   sessionStorage.removeItem('userInfo')
    // }
    // next({ name: 'login', query: { from: to.name }, params: to.params })
    next()
  }

})


export default router
