
const config = {
    button: {
        bg_color: "rgb(146, 182, 213)",
        fg_color: "#ffffff"
    },
}


export default config;
