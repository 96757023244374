import JSEncrypt from "jsencrypt";


const publicKey =
	"MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCNLl7w33AgiMsiG397f8udIUd7+UQLgOp8a0g+UAf2SEXBXA7NQUhycxRpZv/UFo8R7ymMkSrSskTw3XI443vg9Wo2kOmjXPmimbopvvvN4amuZU7k78NUN9drjTcAUmDp/ybGco6h72lcWhuKu8kra3TCQXLLXzZNpAix+JNh6wIDAQAB";
const privateKey =
	"MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAI0uXvDfcCCIyyIbf3t/y50hR3v5RAuA6nxrSD5QB/ZIRcFcDs1BSHJzFGlm/9QWjxHvKYyRKtKyRPDdcjjje+D1ajaQ6aNc+aKZuim++83hqa5lTuTvw1Q312uNNwBSYOn/JsZyjqHvaVxaG4q7yStrdMJBcstfNk2kCLH4k2HrAgMBAAECgYBexdQh6jxA+tMx8Deh1eX/cwmjpW8SMdY282Wpvtu5bka1SHD/RGW3vpzjLKtr2kDsjxnbLNAMZHN3JZC5s6vCE/RvB3/bys9/uR5eQeCYJ2wDKi00iZQRyzLLJgDd0TjWFDkSUU3LGaOAzoMtJ6mMbbm4BiYWiiThwG1yZtbWwQJBANrllxTJlRCcIqjQ8+/RkijQxBstqDhdnJIjmQmiq7amnSTMlci6UcM95bAraFnSTZ6rWTvEMSo1a5Z9DPWSmtsCQQClHIcFtDMPBpWedJ/1Inh42WmGtAXB5yKRw2FXh5mBJJIF0zLHgWbtk86KuaK+kDsVFLKWIbKcSDB5M4Fr1JoxAkBIl7dA15EtzvnXjmQ3dgLIsmM2uC9/cdwIwvFvtpjHm/2lRIuv48pVu2M+x0Z18z52jlCrd2bunoSthY8UqafdAkAKW2rA2dfmFmSmRuyNxPbHpeOvtnUx/5y0hgwCIJhpeHDMer4Rs7VQK9hl7VUfHQ8HuH+nWT/J++FGiQuSRwjRAkEAzAwMMHEdyqYsqCYUZf4J4L/3WHxWVgMMGsfqp6+VqfddJbBDZkFavwB5oeud+WO7+I+q/VwcjkhSBPVuhODeaA==";
	
export default {
	decryptText(encryptedText) {
	  let decryptor = new JSEncrypt();
	  // 设置私钥
	  decryptor.setPrivateKey(privateKey);
	  // 解密数据
	  return decryptor.decrypt(encryptedText);
	},
	
	encryptText(decryptedText) {
	  let encryptor = new JSEncrypt();
	  // 设置公钥
	  encryptor.setPublicKey(publicKey);
	  // 加密数据
	  return encryptor.encrypt(decryptedText);
	}
}	
