<template>   
<div class="home">
<el-container>
      <el-header>
        <HeaderBarVue :zh_en="$route.params.zh_en" :name="$route.name" /> 
      </el-header>
      <router-view/>
</el-container>
<div class="hoverIcon" v-show="showIcon" @click="toAlert">
<!-- <div class="hoverIcon" v-show="showIcon"> -->
  <div class="iconDiv">
    <img src="@/assets/img/login/hoverIcon.svg" alt="" class="iconIMG">
  </div>
  <div class="pointDiv">
    <img src="@/assets/img/login/point.svg" alt="" class="pointIMG">
  </div>
  
</div>
</div>
</template>
<!-- <script src="./utils/googleAPI"></script> -->
<script>
import HeaderBarVue from "@/components/global/HeaderBar.vue";
import CircularJSON from 'circular-json'
const _ = require("lodash");
// import googleAPI from '@/utils/googleAPI'
export default{
  components:{
    HeaderBarVue
  },
  // props: {
  //   zh_en: {
  //     type: String,
  //     default: "ch",
  //   },
  // },
  data(){
    return{
      // * 定时函数
      drawTiming: null,
      path:this.$route.path,
      params:this.$route.params?this.$route.params:'en',
      showIcon:false,
    }
  },
  watch:{
    $route(value){
      if (value.name!="messageManagement" && value.name!="main"){
        this.showIcon = true
      }else{
        this.showIcon = false
      }
    }
  },
  computed:{
    // showIcon(){
    //   let aaa = JSON.parse(sessionStorage.getItem("userInfo"))
    //   if ( aaa &&
    //    aaa.uid &&
    //    this.$route.query.uid &&
    //    aaa.uid==this.$route.query.uid){
    //     return true
    //   }else{
    //     return false
    //   }
      
    // }
  },
  async mounted(){
    // 使用谷歌登录的api
    if(document.documentElement.clientWidth>750){
      // const uid = document.cookie.replace(/(?:(?:^|.*;\s*)uid\s*\=\s*([^;]*).*$)|^.*$/, "$1")
      // const email = document.cookie.replace(/(?:(?:^|.*;\s*)email\s*\=\s*([^;]*).*$)|^.*$/, "$1")
      // const uid = this.getCookie('uid')
      // const email = this.getCookie('email')
      // console.log('cookie-uid:',uid)
      //   console.log('cookie-email:',email)
      // if (uid&&email){
      //   console.log('cookie-uid:',uid)
      //   console.log('cookie-email:',email)
      //   this.$route.query.uid = uid
      //   sessionStorage.setItem(
      //         "userInfo",
      //         JSON.stringify({ email: email, uid: uid })
      //       );
      // }
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    window.tvSrc = "https://s3.tradingview.com/tv.js";
    // window.tvSrc = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    const script2 = document .createElement("script");
    script2.src = window.tvSrc;
    // script.src = "./utils/googleAPI";
    document.body.appendChild(script);
    document.body.appendChild(script2);
    // await this.$nextTick(()=>{
    //   if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
      
    // }
    // const uid = document.cookie.replace(/(?:(?:^|.*;\s*)uid\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    //   const email = document.cookie.replace(/(?:(?:^|.*;\s*)email\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    //   console.log('document.cookie:',uid)
    //   console.log('document.cookie:',email)
    // })
    }else{
      this.$router.replace({name:"moveMain",params:this.$route.params.zh_en?this.$route.params:{zh_en:'en'}})
    }

    
    
  },
  
  methods:{
    // passUserInfo(uid){
    //   if (uid && this.$route.query.uid && this.$route.query.uid == uid){
    //     this.showIcon = true
    //   }else{
    //     this.showIcon = false
    //   }
    // },
    getCookie(name) {
      var cookieArr = document.cookie.split("; ");
      for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (cookiePair[0] === name) {
        return decodeURIComponent(cookiePair[1]);
      }
      }
      return null;
    },
    toAlert(){
      let route = {}
      route.name = _.cloneDeep(this.$route.name)
      route.params = _.cloneDeep(this.$route.params)
      route.query = _.cloneDeep(this.$route.query)
      // sessionStorage.setItem("fromRoute",CircularJSON.stringify(this.$route))
      sessionStorage.setItem("fromRoute",JSON.stringify(route))
      let aaa = JSON.parse(sessionStorage.getItem("userInfo"))
      if (aaa && this.$route.query.uid && aaa.uid && aaa.uid==this.$route.query.uid && this.$route.name!="main"){
        if (this.$route.name != 'messageManagement'&&this.$route.name != 'login' && this.$route.name != 'register'&& this.$route.name != 'foregetPwd'){
        if (this.$route.name=='menu'){
          this.$router.push({name:"messageManagement",params:this.$route.params.zh_en?this.$route.params:{zh_en:'en'},query: { name:this.$route.name,uid: this.$route.query.uid?this.$route.query.uid:'',coin:this.$route.params.coin,type:this.$route.params.type,isIcon:true,channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'', }})
        }else{
          this.$router.push({name:"messageManagement",params:this.$route.params.zh_en?this.$route.params:{zh_en:'en'},query: { name:this.$route.name,uid: this.$route.query.uid?this.$route.query.uid:'',isIcon:true,channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'', }})
        }
        
      }
      }else{
        this.$router.push({
        name: "login",
        params: {
          zh_en: this.zh_en === "en" ? "en" : "ch",
        },
        query: {
          from: "messageManagement",
          name:this.$route.name,
          coin:this.$route.params.coin,
          type:this.$route.params.type,
          isIcon:true,
          channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
        },
      });
        // this.showIcon = false
      }
      
      
    }
  }
}

</script>
<style lang="scss">
@import "@/assets/main.css";
#app {
  font-family: 'Outfit-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.home {
  min-width: 1440px;
  background-color: #ededed;
  position: relative;
  .hoverIcon{
    position: absolute;
    cursor: pointer;
    right: 55px;
    bottom: 55px;
    width: 50px;
    height: 50px;
    border-radius: 50% 50%;
    background-color: #fff;
    box-shadow:0 0 7px #666666 ;
    .iconDiv{
      position: absolute;
      top: 4px;
      right: 4px;
      width: 42px;
      height: 42px;
      text-indent: -42px;
      overflow: hidden;
      .iconIMG{
      width: 42px;
      height: 42px;
      filter: drop-shadow(42px 0 0 #036cfc);
    }
    }
    .pointDiv{
      position: absolute;
      top: -1px;
      right: -3px;
      width: 30px;
      height: 30px;
      text-indent: -30px;
      overflow: hidden;
      .pointIMG{
      width: 30px;
      height: 30px;
      filter: drop-shadow(30px 0 0 #ff2a66);
    }
    }
    
  }
}
.el-header {
  --el-header-height: 50px;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
