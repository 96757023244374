<template>
  <el-main class="content">
    <div class="card" :data-number="a">
      <el-tabs
        v-model="activeName"
        stretch
        type="card"
        class="demo-tabs"
        @tab-click="handleClick"
        @tab-change="changeTab"
      >
        <el-tab-pane
          :label="$route.params.zh_en == 'en' ? 'Sign up' : '注册'"
          name="Sign_up"
        >
          <div class="emailTitle">
            {{ $route.params.zh_en == "en" ? "Email" : "邮箱" }}
          </div>
          <el-input
            class="emailInput"
            v-model="email"
            :placeholder="
              $route.params.zh_en == 'en' ? 'Please input email' : '请输入邮箱'
            "
            @input="emailInput"
          />
          <div class="VerificationTitle">
            {{ $route.params.zh_en == "en" ? "Verification Code" : "验证码" }}
          </div>
          <div class="VerificationContent">
            <el-input
              class="VerificationInput"
              v-model="signUpCode"
              :placeholder="
                $route.params.zh_en == 'en'
                  ? 'Please input verification Code'
                  : '请输入验证码'
              "
            />
            <el-button
              @click="getRegisterVervify(email)"
              color="#5F82E6"
              class="codeButton"
              :disabled="loading"
              >{{
                $route.params.zh_en == "en"
                  ? sec + " Get Code"
                  : sec + " 获取验证码"
              }}
            </el-button>
          </div>

          <div class="passwordTitle">
            <div>{{ $route.params.zh_en == "en" ? "Password" : "密码" }}</div>
          </div>
          <el-input
            class="passwordInput"
            v-model="signUpPassword"
            :placeholder="
              $route.params.zh_en == 'en'
                ? 'Please input your password'
                : '请输入你的密码'
            "
          />
          <div class="loginTitle">
            <div>
              {{
                $route.params.zh_en == "en"
                  ? "Don’t you have an account?"
                  : "已经有账号了？"
              }}
            </div>
            <div class="toLogin" @click="toLogin">
              {{ $route.params.zh_en == "en" ? "Log in" : "去登录" }}
            </div>
          </div>
          <el-button @click="signUp" color="#5F82E6" class="loginButton">{{
            $route.params.zh_en == "en" ? "Sign up" : "注册"
          }}</el-button>
        </el-tab-pane>
        <el-tab-pane
          :label="$route.params.zh_en == 'en' ? 'Log in' : '登录'"
          name="Sign_in"
        >
          <div class="emailTitle">
            {{ $route.params.zh_en == "en" ? "Email" : "邮箱" }}
          </div>
          <el-input
            class="emailInput"
            v-model="signInEmail"
            :placeholder="
              $route.params.zh_en == 'en' ? 'Please input email' : '请输入邮箱'
            "
            @input="emailInput2"
          />
          <div class="passwordTitle">
            <div>{{ $route.params.zh_en == "en" ? "Password" : "密码" }}</div>
            <div class="forgotPassword" @click="toForegetPWD">
              {{ $route.params.zh_en == "en" ? "Forgot password" : "忘记密码" }}
            </div>
          </div>
          <el-input
            type="password"
            class="passwordInput"
            v-model="signInPassword"
            :placeholder="
              $route.params.zh_en == 'en'
                ? 'Please input your password'
                : '请输入你的密码'
            "
          />
          <div class="holdSignIn">
            <el-switch
            v-model="holdSignIn"
            active-color="#5F82E6"
            width=40
          >
          </el-switch>
          <span class="text">{{$route.params.zh_en == 'en'?'Maintain a log-in status for the next 30 days':'近一个月内保持登录状态'}}</span>
          </div>
          <div class="loginTitle">
            <div>
              {{
                $route.params.zh_en == "en"
                  ? "Don’t you have an account?"
                  : "还没有账号？"
              }}
            </div>
            <div class="toLogin" @click="toRegister">
              {{ $route.params.zh_en == "en" ? "Sign up" : "去注册" }}
            </div>
          </div>
          <el-button color="#5F82E6" @click="signIn" class="loginButton">
            <span class="login">
              {{ $route.params.zh_en == "en" ? "Log in" : "登录" }}
            </span>
          </el-button>
        </el-tab-pane>
        <div class="bottom">
          <div class="or">
            <div class="line1"></div>
            <div class="orContent">
              {{ $route.params.zh_en == "en" ? "Or" : "或者" }}
            </div>
            <div class="line2"></div>
          </div>
          <div class="toGeogle" style="overflow: hidden; position: relative">
            <div class="g_id_signin" id="g_id_signin"></div>
            <el-button
              color="#ffffff"
              class="geogleButton"
            >
              <img
                class="googleIcon"
                src="@/assets/img/login/Google__G__Logo.svg"
                alt=""
              />
              <span class="google">{{
                $route.params.zh_en == "en"
                  ? "Log in with Google"
                  : "使用谷歌登录"
              }}</span></el-button
            >
          </div>
          <div class="notes">
            {{
              $route.params.zh_en == "en"
                ? "Sign up implies acceptance of "
                : "注册即代表同意"
            }}
            <span class="agreement" @click="toAgreement">
              {{
                $route.params.zh_en == "en"
                  ? " the Terms & Conditions "
                  : "《用户协议》"
              }}
            </span>
          </div>
        </div>
      </el-tabs>
    </div>
  </el-main>
</template>
  <script>
import { ElMessage } from "element-plus";
import Request from "../utils/requests/base_request";
import jwt_decode from "jwt-decode";
import switchMove from "@/mixin/switchMove";

export default {
  //注册mixin
  mixins: [switchMove],
  name: "LoginView",
  props: ["zh_en", "name"],
  components: {},
  data() {
    return {
      loading: false,
      loading2: false,
      sec: "",
      sec2: "",
      activeName: "Sign_in",
      email: "",
      signUpCode: "",
      a:0,
      signInEmail: "",
      signInPassword: "",
      signUpPassword: "",
      holdSignIn:false,
      a: "0",
      currentFrom: "",
      coin: "",
      type: "",
      // categoryName: "",
      // dataSource: "",
      params: {},
      client_id:
        "1036910501552-v03etf5e0s8c73l3uo3ldc7bpp4ltol3.apps.googleusercontent.com",
      // googleSignInParams: {
      //   client_id: '1036910501552-v03etf5e0s8c73l3uo3ldc7bpp4ltol3.apps.googleusercontent.com'
      // }
    };
  },
  async mounted() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
    window.addEventListener("load", async() => {   
    await this.$nextTick(() => {
      if (window.google) {
        // this.gload().then(()=>{
          
        // }).catch((e)=>{})
        window.google.accounts.id.initialize({
          // 主要就是填写client_id
          client_id: this.client_id,
          // auto_select: false,
          // context:"use",
          callback: this.handleCredentialResponse,
        });

          window.google.accounts.id.renderButton(
          document.getElementById("g_id_signin"),
          {
            // theme: "outline",
            // shape: "circle",
            // size: "large",
            // width: "200",
            // type: "standard",
            // text: "Log in with Google",
            // logo_alignment: "center",
          }
        );
        
      }
      
    });

    });
    
  },
  watch: {
    $route: {
      handler(newMenu) {
        this.currentFrom = newMenu.query.from;
    this.coin = newMenu.query.coin?newMenu.query.coin:'';
    this.type = newMenu.query.type?newMenu.query.type:'';
    // this.categoryName = newMenu.query.categoryName?newMenu.query.categoryName:'';
    // this.dataSource = newMenu.query.dataSource?newMenu.query.dataSource:'';
        if (newMenu.name == "register") {
          this.activeName = "Sign_up";
          if (newMenu.params.zh_en === "en") {
            this.a = "-115px";
          } else {
            this.a = "-105px";
          }
        } else if (newMenu.name == "login") {
          this.activeName = "Sign_in";
          if (newMenu.params.zh_en === "en") {
            this.a = "0";
          } else {
            this.a = "-20px";
          }
        }
      },
      immediate: true,
    },
    // google: {
    //   handler(newValue) {
    //     console.log("window:", newValue);
    //   },
    // },
  },
  computed: {
    // google() {
    //   window.addEventListener("load", () => {
    //     let sec = 2;
    //     const intervalTimeId = setInterval(async function () {
    //       if (sec === 0) {
    //         sec = "";
    //         clearInterval(intervalTimeId);
    //         // this.add()
    //       } else {
    //         sec -= 1;
    //       }
    //     }, 1000);
    //   });
    //   return !sec ? window.google : "";
    // },
  },
  methods: {
    gload(){
      var that = this
      return new Promise(function(a,b){
        window.google.accounts.id.initialize({
          // 主要就是填写client_id
          client_id: that.client_id,
          // auto_select: false,
          // context:"use",
          callback: that.handleCredentialResponse,
        });
        a()
      }).catch((e)=>{})
    },
    async handleCredentialResponse(response) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      let code = response.credential;
      const decode = jwt_decode(code);
      const { email, sub } = decode;
      if (decode) {
        if (
          this.currentFrom == "selectCurrency" ||
          this.currentFrom == "macroscopicInvestment" ||
          this.currentFrom == "riskReminder"
        ) {
          this.params = {
            zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
            tab: "home",
          };
        } else if (this.currentFrom == "menu") {
          this.params = {
            zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
            coin: this.coin,
            type: this.type,
          };
        } else {
          this.params = {
            zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
          };
        }
        await this.$requests
          .googleSignIn({ email: email, sub: sub })
          .then((res) => {
            if (res.data.code == "success") {
              ElMessage({
                type: "success",
                message:
                  this.$route.params.zh_en == "en"
                    ? "Login successful"
                    : "登录成功",
              });
              sessionStorage.setItem(
                "userInfo",
                JSON.stringify({ uid: res.data.UID })
              );
              if (
                this.currentFrom == "login" ||
                this.currentFrom == "register" ||
                this.currentFrom == "foregetPwd" ||
                !this.$route.query ||
                !this.$route.query.from
              ) {
                this.$router.replace({
                  name: "main",
                  params: this.params,
                  query: {
                    uid: res.data.UID,
                    channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                  },
                });
              } else {
                if (
                  this.currentFrom == "menu" &&
                  (!this.$route.query.coin || !this.$route.query.type)
                ) {
                  this.$router.replace({
                    name: "main",
                    params: this.params,
                    query: {
                      uid: res.data.UID,
                      channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                    },
                  });
                } else {
                  this.$router.replace({
                    name: this.currentFrom,
                    params: this.params,
                    query: {
                      uid: res.data.UID,
                      channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                    },
                  });
                }
              }
            } else {
              ElMessage({
                type: "warning",
                message:
                  this.$route.params.zh_en == "en"
                    ? "Google login failed"
                    : "谷歌登录失败",
              });
            }
          });
      }
    },
    // async GoogleLoginWithIdToken(idToken) {
    //   let res = await GoogleLoginWithIdToken(idToken)
    //   if (res.code === 0) {
    //     console.log('Google login success')
    //   }
    // },
    // 注册邮箱输入时，去除空格
    emailInput(email) {
      this.email = email.replace(/\s*/g, "");
    },
    // 登录邮箱输入时，去除空格
    emailInput2(email) {
      this.signInEmail = email.replace(/\s*/g, "");
    },

    // 获取注册邮箱验证码
    getRegisterVervify(email) {
      if (this.validateEmail(email)) {
        this.$requests.getRegisterVervify({ email }).then((res) => {
          if (res.data.msg == "have send") {
            this.add();
            ElMessage({
              type: "success",
              message:
                this.$route.params.zh_en == "en"
                  ? "The verification code has been sent to the email."
                  : "验证码已发送至邮箱",
            });
          } else {
            ElMessage({
              type: "warning",
              message:
                this.$route.params.zh_en == "en"
                  ? "The email account already exists, please log in directly."
                  : "该邮箱账号已存在，请直接登录！",
            });
          }
        });
      } else {
        ElMessage({
          type: "warning",
          message:
            this.$route.params.zh_en == "en"
              ? "The email format is incorrect, please re-enter."
              : "邮箱格式有误，请重新输入",
        });
      }
    },

    // 校验邮箱格式
    validateEmail(email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    timestampToTime(timestamp) {
      let date = new Date(timestamp);
      let formattedTime = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(date);
      return formattedTime;
    },
    signIn() {
      if (
        this.currentFrom == "selectCurrency" ||
        this.currentFrom == "macroscopicInvestment" ||
        this.currentFrom == "riskReminder"
      ) {
        this.params = {
          zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
          tab: "home",
        };
      } else if (this.currentFrom == "menu") {
        this.params = {
          zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
          coin: this.coin,
          type: this.type,
        };
      } else if(this.currentFrom == "selectTrackDetail") {
        this.params = {
          zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
          categoryName:this.$route.query.categoryName,
          dataSource:this.$route.query.dataSource
        };
      } else {
        this.params = {
          zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
        };
      }
      this.$requests
        .signIn({ email: this.signInEmail, password: this.signInPassword })
        .then((res) => {
          if (res.data.msg == "email or password pass error") {
            ElMessage({
              type: "warning",
              message:
                this.$route.params.zh_en == "en"
                  ? "Account or password error, please log in again."
                  : "账号或密码错误，请重新登录！",
            });
          } else if (res.data.msg == "Login successful") {
            // console.log("this.currentFrom::", this.currentFrom);
            ElMessage({
              type: "success",
              message:
                this.$route.params.zh_en == "en"
                  ? "Login successful"
                  : "登录成功",
            });
            if (this.holdSignIn){
              const now = new Date()
              let overTime = now.getTime()+30*86400000
              const overDate = this.timestampToTime(overTime).toString()
              // document.cookie = 'email='+res.data.EMAIL+'; '+'max-age=2592000'
              document.cookie = 'uid='+res.data.UID+'; '+'path=/;max-age=2592000;'
            }
            sessionStorage.setItem(
                "userInfo",
                JSON.stringify({ uid: res.data.UID })
            );
            // console.log('login:this.$route:',this.$route)
            if (
              this.currentFrom == "login" ||
              this.currentFrom == "register" ||
              this.currentFrom == "foregetPwd" ||
              !this.$route.query ||
              !this.$route.query.from
            ) {
              this.$router.replace({
                name: "main",
                params: this.params,
                query: {
                  uid: res.data.UID,
                  channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                },
              });
            } else if (this.currentFrom == "messageManagement"){
              this.$router.replace({
                name: "messageManagement",
                params: this.params,
                query: {
                  uid: res.data.UID,
                  name:this.$route.query.name,
                  coin:this.$route.query.coin,
                  type:this.$route.query.type,
                  isIcon:true,
                  channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                },
              });
            } else if (this.currentFrom == "selectTrackDetail"){
              this.$router.replace({
                name: "selectTrackDetail",
                params: this.params,
                query: {
                  uid: res.data.UID,
                  menu:this.$route.query.menu,
                  channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                },
              });
            } else {
              try {
                this.$router.replace({
                name: this.currentFrom,
                params: this.params,
                query: {
                  uid: res.data.UID,
                  channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                },
              });
              } catch (error) {
                this.$router.replace({
                name: "home",
                params: this.params,
                query: {
                  uid: res.data.UID,
                  channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
                },
              });
              }
              
            }
          }
        });
    },
    signUp() {
      if (this.validateEmail(this.email)) {
        if (this.signUpCode) {
          if (this.signUpPassword) {
            this.$requests
              .signUp({
                email: this.email,
                password: this.signUpPassword,
                shortID: this.signUpCode,
              })
              .then((res) => {
                if (res.data.msg == "email already exists") {
                  ElMessage({
                    type: "warning",
                    message:
                      this.$route.params.zh_en == "en"
                        ? "This account already exists, please log in directly."
                        : "该账号已存在，请直接登录！",
                  });
                } else {
                  ElMessage({
                    type: "success",
                    message:
                      this.$route.params.zh_en == "en"
                        ? "Account registration successful."
                        : "账号注册成功！",
                  });
                  this.signInEmail = this.email;
                  this.signInPassword = this.signUpPassword;
                  this.signIn();
                }
              });
          } else {
            ElMessage({
              type: "warning",
              message:
                this.$route.params.zh_en == "en"
                  ? "Please enter the password."
                  : "请输入密码",
            });
          }
        } else {
          ElMessage({
            type: "warning",
            message:
              this.$route.params.zh_en == "en"
                ? "Please enter the verification code."
                : "请输入验证码",
          });
        }
      } else {
        ElMessage({
          type: "warning",
          message:
            this.$route.params.zh_en == "en"
              ? "The email format is incorrect, please re-enter."
              : "邮箱格式有误，请重新输入",
        });
      }
    },

    changeTab(name) {
      if (name == "Sign_up") {
        this.$router.push({
          name: "register",
          params: { zh_en: this.$route.params.zh_en === "en" ? "en" : "ch" },
          query: {
            from: this.$route.query.from,
            channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
          },
        });
      } else {
        this.$router.push({
          name: "login",
          params: {
            zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
          },
          query: {
            from: this.$route.query.from,
            channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
          },
        });
      }
    },
    toRegister() {
      this.activeName = "Sign_up";
    },
    toLogin() {
      this.activeName = "Sign_in";
    },
    async toForegetPWD() {
      this.$router.push({
        name: "foregetPwd",
        params: { zh_en: this.$route.params.zh_en === "en" ? "en" : "ch" },
        query: this.$route.query,
        channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
      });
    },

    add() {
      let _this = this;
      // _this.getUpdateTime()
      _this.loading = true;
      _this.sec = 60;
      _this.intervalTimeId = setInterval(async function () {
        if (_this.sec === 0) {
          _this.sec = "";
          _this.loading = false;

          clearInterval(_this.intervalTimeId);
          // this.add()
        } else {
          _this.sec -= 1;
        }
      }, 1000);
    },

    toAgreement() {
      this.$router.replace({
        name: "agreement",
        params: {
          zh_en: this.$route.params.zh_en === "en" ? "en" : "ch",
        },
        query:{
          channel:this.$route.query.channel?this.$route.query.channel:'',num:this.$route.query.num?this.$route.query.num:'',
        }
      });
    },
  },
};
</script>
  
<style lang="scss" scoped>

.g_id_signin {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.0001;
  width: 100%;
  height: 50px;
  z-index: 100;
  cursor: pointer;
}
// .g-signin-button {
//   /* This is where you control how the button looks. Be creative! */
//   display: inline-block;
//   padding: 4px 8px;
//   border-radius: 3px;
//   background-color: #3c82f7;
//   color: #fff;
//   box-shadow: 0 3px 0 #0f69ff;
// }
.content {
  display: flex;
  flex-direction: column;

  align-items: center;
  background: url("@/assets/img/login/Pricing.png");
  .passwordTitle {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #000000;
    font-family: Outfit-Bold, Outfit;
    font-weight: bold;
    margin: 25px 0 11px;
    .forgotPassword {
      font-family: Outfit-Regular, Outfit;
      font-weight: 400;
      color: #72767d;
      cursor: pointer;
    }
  }
  .holdSignIn{
    height: 32px;
    margin-top: 20px;
    display: inline-flex;
    justify-content: left;
    align-items:center;
    .text{
      font-size:16px;
      margin-left: 10px;
      color:#72767d;
      font-family: Outfit-Regular, Outfit;
    }
  }
  .loginTitle {
    font-size: 16px;
    font-family: Outfit-Bold, Outfit;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin: 20px 0 5px;
    .toLogin {
      font-family: Outfit-Regular, Outfit;
      font-weight: 400;
      color: #5f82e6;
      cursor: pointer;
    }
  }
  .codeButton {
    // width: 100%;
    height: 52px;
  }
  .loginButton {
    width: 100%;
    height: 52px;
  }
  .geogleButton {
    width: 100%;
    height: 52px;
  }
  .bottom {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 300px;
    .or {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .line1 {
        width: 233px;
        height: 1px;
        background-color: #e4e4eb;
        // border: 2px solid #e4e4eb;
      }
      .orContent {
        width: 58px;
        font-size: 16px;
        text-align: center;
        font-family: 'Outfit-Bold';
        font-weight: 600;
      }
      .line2 {
        width: 233px;
        height: 1px;
        background-color: #e4e4eb;
      }
    }
    .toGeogle {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      height: 52px;
    }
    .notes {
      font-size: 12px;
      color: #999999;
      text-align: center;
      .agreement {
        cursor: pointer;
      }
    }
  }
}
.login {
  font-family: Outfit-Bold, Outfit;
}
.googleIcon {
  width: 28px;
  margin-right: 10px;
}
.google {
  font-size: 16px;
  font-family: 'Outfit-Bold';
  font-weight: 600;
  color: #000000;
}

.card {
  position: relative;
  padding: 100px 0 0 0;
  font-size: 16px;
}
.card[data-number="0"]::after {
  content: "";
  position: absolute;
  top: 140px;
  left: 322px;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  background-color: #5f82e6;
  transform: translateX(0);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.card[data-number="-20px"]::after {
  content: "";
  position: absolute;
  top: 140px;
  left: 322px;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  background-color: #5f82e6;
  transform: translateX(-20px);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.card[data-number="-105px"]::after {
  content: "";
  position: absolute;
  top: 140px;
  left: 322px;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  background-color: #5f82e6;
  transform: translateX(-105px);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.card[data-number="-115px"]::after {
  content: "";
  position: absolute;
  top: 140px;
  left: 322px;
  width: 8px;
  height: 4px;
  border-radius: 2px;
  background-color: #5f82e6;
  transform: translateX(-115px);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.demo-tabs {
  width: 528px;
  font-size: 22px;
  font-family: Outfit-Bold, Outfit;
  font-weight: bold;
}
.el-tabs {
  --el-border-color-light: transparent !important;
  --el-font-size-base: 22px;
  --el-color-primary: #5f82e6;
  --el-text-color-primary: #000000;
}
.el-tabs__active-bar {
  width: 15px !important;
}
.el-tabs__header {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 50% !important;
}
.el-input {
  --el-input-text-color: #000000;
  --el-text-color-regular: #000000;
  --el-component-size: 52px;
  --el-input-height: 52px;
  // --el-input-border-color: #ffffff;
  --el-border-color: #e4e4eb;
  --el-color-primary: #5f82e6;
}
:deep.el-input .el-input__wrapper {
  padding: 1px 1px !important;
}
:deep.el-input .el-input__wrapper .el-input__inner {
  padding: 1px 10px !important;
  border-radius: 4px;
  font-family: 'Outfit-Regular';
}
// .el-input:focus-within {
//   border: 1px solid#5f82e6 !important;
// }
.emailTitle {
  font-size: 16px;
  color: #000000;
  font-family: Outfit-Bold, Outfit;
  font-weight: bold;
  margin-top: 7px;
  margin-bottom: 11px;
}
.emailInput input {
  font-size: 16px !important;
  font-family: Outfit-Regular, Outfit !important;
  font-weight: 400 !important;
  color: #000000 !important;

  // --el-input-bg-color:#F4F9FE !important;
  // --el-fill-color-blank:#F4F9FE !important;
}
.VerificationTitle {
  font-size: 16px;
  color: #000000;
  font-family: Outfit-Bold, Outfit;
  font-weight: bold;
  margin-top: 7px;
  margin: 25px 0 11px;
}
.VerificationContent {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}
.VerificationInput {
  width: 70%;
}
.VerificationInput input {
  font-size: 16px !important;
  font-family: Outfit-Regular, Outfit !important;
  font-weight: 400 !important;
  color: #000000 !important;
  background-color: #f4f9fe !important;
}
.codeButton.el-button {
  --el-button-hover-bg-color: #5f82e6 !important;
  --el-button-text-color: #ffffff;
  --el-color-black: #ffffff;
  --el-font-size-base: 16px;
  --el-font-weight-primary: bold;
  font-family: 'Outfit-Bold';
}
.loginButton.el-button {
  --el-button-hover-bg-color: #5f82e6 !important;
  --el-button-text-color: #ffffff;
  --el-color-black: #ffffff;
  --el-font-size-base: 16px;
  --el-font-weight-primary: bold;
  font-family: 'Outfit-Bold';
}
.geogleButton.el-button {
  --el-button-hover-border-color: #e4e4e8 !important;
  --el-button-border-color: #e4e4e8 !important;
  --el-font-size-base: 16px;
  --el-font-weight-primary: bold;
  font-family: 'Outfit-Bold';
}

::v-deep .el-tabs__nav.is-stretch {
  justify-content: center !important;
}
::v-deep .el-tabs__nav.is-stretch > * {
  flex: 0.1 !important;
}
// iframe {
//   width: 420px;
//   left: 56px !important;
//   margin: 0 !important;
//   .haAclf {
//     padding: 0 !important;
//     .nsm7Bb-HzV7m-LgbsSe {
//       max-width: none !important;
//     }
//   }
// }
</style>
<style>
.S9gUrf-YoZ4jf iframe{
  /* transform: scaleX(1.818) scaleY(1.18) translateX(55px) translateY(4px); */
  transform: scaleX(2.4) scaleY(1.18) translateX(68px) translateY(4px);
}
.emailInput .el-input__inner {
  background-color: #f4f9fe !important;
  font-size: 16px !important;
}
.VerificationInput .el-input__inner,
.passwordInput .el-input__inner {
  /* background-color: #ffffff !important; */
  font-size: 16px !important;
}
</style>
  
  