export default{
    mounted(){
        this.calcRate()
        window.addEventListener('resize', this.resize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize)
    },
    methods:{
        resize() {
            //先清除计时器
            clearTimeout(this.drawTiming)
            //开启计时器
            this.drawTiming = setTimeout(() => {
                this.calcRate()
            }, 200)
        },
    calcRate(){
      if(document.documentElement.clientWidth<=750){
      this.$router.replace({name:"moveMain",params:this.$route.params.zh_en?this.$route.params:{zh_en:'en'}})
    }else{
      if(this.path == '/en/moveHome'||this.path == '/ch/moveHome'){
        this.$router.replace({name:"main",params:this.$route.params.zh_en?this.$route.params:{zh_en:'en'}})
      }
    }
    }
    }
}