
import Request from "./base_request";
import axios from 'axios';
const CancelToken = axios.CancelToken

const development = "development_"

export default {
    // Portfolio-account：更新account的表格和导航图
    queryAccountInfo(modle){
        // portfolioId：portfolio的ID

        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/tracker/portfolioManage/queryAccountInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-holdings：更新holdings的表格和饼图
    queryHoldingInfo(modle){
        // portfolioId：portfolio的ID
        // orderCol：排序字段 {"PROJECT","Holdings_Type","LISTING_OR_NOT","LOCKED_OR_NOT","Total_Profit_Loss","Daily_Profit_Loss","Realized_Profit_Loss","Floating_Profit_Loss","Floating_Profit_Loss_Ratio","Avg_Buy_Price","Current_Price","Holdings_Quantity","Holdings_Market_Cap","Holdings_Percentage"}其中之一
        // orderType：排序类型 {“asc”，“desc”} 其中之一

        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/tracker/portfolioManage/queryHoldingInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-holdings：更新holdings的表格和饼图
    updatePortfolioCharts(modle){
        // portfolioId：portfolio的ID

        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/tracker/portfolioManage/updatePortfolioCharts",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：删除investmentLocked数据
    deleteInvestmentLockedInfo(modle){
        // portfolioId：portfolio的ID
        // investmentId：investment的ID
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/deleteInvestmentLockedInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：删除investment数据
    deleteInvestmentInfo(modle){
        // portfolioId：portfolio的ID
        // investmentId：investment的ID
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/deleteInvestmentInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：保存investment总体信息
    saveInvestmentInfo(modle){
        
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "POST",
            url: "/DataAnalyse/tracker/portfolioManage/saveInvestmentInfo",
            data: JSON.stringify(modle),
            // headers:{
            //     'Content-Type':'application/json'
            // }
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：查询investment总体信息
    queryInvestmentInfos(modle){
        // portfolioId portfolioId
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/queryInvestmentInfos",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：查询指定日期的价格，市值信息
    queryPriceMarketCapInfo(modle){
        // fullName：项目全称
        // coinName：币名
        // InvestmentTime：时间,必输（格式为：yyyy-mm-dd）+
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/priceVolume/queryPriceMarketCapInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：查询所有融资类型
    queryFundraisingTypes(){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/fundamental/queryFundraisingTypes",
        }
    
        return Request.request(config)
    },
    // Portfolio-investment：通过fullname查询项目信息
    queryBaseInfoByFullName(modle){
        // fullName：项目全称
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/fundamental/queryBaseInfoByFullName",
            params: modle
        }
    
        return Request.request(config)
    },
    // 修改用户Portfolio信息
    deletePortfolioInfo(modle){
        // UID 用户ID
        // portfolioId portfolioId
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/deletePortfolioInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // 修改用户Portfolio信息
    updatePortfolioInfo(modle){
        // UID 用户ID
        // portfolioId portfolioId
        // portfolioName portfolio名称
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/updatePortfolioInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // 创建用户Portfolio信息
    createPortfolioInfo(modle){
        // UID 用户ID
        // portfolioName portfolio名称
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/createPortfolioInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // 获取用户Portfolio信息
    queryPortfolioInfoByUID(modle){
        // UID 用户ID
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/portfolioManage/queryPortfolioInfoByUID",
            params: modle
        }
    
        return Request.request(config)
    },
    // 获取币种项目信息
    queryProjectInfoAndFundrasing(modle){
        // falconId 
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/fundamental/queryProjectInfoAndFundraising",
            // data: JSON.stringify(modle),
            params: modle
        }
    
        return Request.request(config)
    },
    // 获取用户信息
    getUserInfo(modle){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/getUserInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // 修改昵称
    changeUsername(modle){
        // UserName: 用户昵称
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/user/user/changeUsername",
            params: modle
        }
    
        return Request.request(config)
    },
    // 退出登录
    logout(modle){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/Logout",
            params: modle
        }
    
        return Request.request(config)
    },
    // 忘记密码
    forgetPassword(modle){
        // email：用户邮箱
        // password: 用户密码
        // shortID: 验证码
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/forgetPassword",
            params: modle
        }
        return Request.request(config)
    },
    // 注册账户
    signUp(modle){
        // email：用户邮箱
        // password: 用户密码
        // shortID: 验证码
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/UserRegister",
            params: modle
        }
    
        return Request.request(config)
    },
    // 登录账户
    signIn(modle){
        // email：用户邮箱
        // password: 用户密码
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/Userloggin",
            params: modle
        }
    
        return Request.request(config)
    },
    // google登录
    googleSignIn(modle){
        // email 谷歌邮箱
        // sub 谷歌返回的sub号
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/LoginByGeogle",
            params: modle
            // url: "/DataAnalyse/user/Bygeogle",
        }
    
        return Request.request(config)
    },
    // 获取注册验证码
    getRegisterVervify(modle){
        // email：用户邮箱
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/register_vervify",
            params: modle
        }
    
        return Request.request(config)
    },
    // 获取忘记密码验证码
    getForegetVervify(modle){
        // email：用户邮箱
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",                    
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/user/user/foreget_vervify",
            params: modle
        }
    
        return Request.request(config)
    },
    // 获取目录
    getMenuData(modle){
        // param
        // model = A0003
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        firstMenu:[
                            
                        ],
                        secondMenu:[
                            
                        ]
                    }
                    
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/system/getMenuData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 获取币种信息
    getCoinInfo(modle){
        // param
        // fullName = btc
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/fundamental/queryCoinInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    //  量价数据 / 量价基本数据摘要
    getPriceVolumesOverviewInfo(modle){
        // param
        // value = btc
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/overview/queryPriceVolumeBaseOverviewInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    //  量价数据 / 量价基本数据
    getPriceVolumes(modle){
        // param
        // value = btc
        // falconId
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/priceVolume/queryPriceVolumeBaseInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    getSource({params,url}){
        // param
        // value = btc
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "/DataAnalyse/"+url,
            params
        }
    
        return Request.request(config)
    },
    getSource1({params,url}){
        // param
        // value = btc
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "/DataAnalyse/"+url,
            params
            // data:JSON.stringify(params)
            
        }
    
        return Request.request(config)
    },
    // 获取全量币种信息
    getFullCurrencys(){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 
        const config = {
            method: "get",
            url: "DataAnalyse/tracker/fundamental/querySupplyCoinsInfo",
        }
    
        return Request.request(config)
    },
    // 获取所有板块的二级分类及板块信息
    getCategoriesInfo(modle){
        // userId
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 
        const config = {
            method: "get",
            url: "DataAnalyse/category/multiFactorCategory/getCategoriesInfo?userId="+modle["userId"]
        }
    
        return Request.request(config)
    },
    // 获取传入板块的板块指数价格
    getCategoriesIndexPriceList(params){
        // param
        // {
        //     ​       categoryIds: [
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'},
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'},
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'},
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'}
        //     ​       ],
        //     ​       rank: 'GT500'
        //     }
            
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 
        const jsonParams = JSON.stringify(params)
        const config = {
            method: "post",
            url: "DataAnalyse/category/multiFactorCategory/getCategoriesIndexPriceList",
            data:jsonParams
            
        }
    
        return Request.request(config)
    },
    // 获取传入板块的板块指数价格变化（7d,30d,90d）
    getCategoriesIndexPriceChangeList(params){
        // param
        // {
        //     ​       categoryIds: [
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'},
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'},
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'},
        //     ​            {categoryId: '板块Id',dateSource:'板块数据源（cmcSource或者falconSource）'}
        //     ​       ],
        //     ​       rank: 'GT500'
        //     }
            
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "DataAnalyse/category/multiFactorCategory/getCategoriesIndexPriceChangeList",
            // params
            data:JSON.stringify(params)
            
        }
    
        return Request.request(config)
    },
    // 获取所有板块的二级分类及板块信息
    getCategoriesIndexPriceBIList(){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 
        const config = {
            method: "get",
            url: "DataAnalyse/category/multiFactorCategory/getCategoriesIndexPriceBIList"
        }
    
        return Request.request(config)
    },
    // 查询市值堆叠图
    getMarketCapStack(){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 
        const config = {
            method: "get",
            url: "DataAnalyse/category/multiFactorCategory/getMarketCapStack"
        }
    
        return Request.request(config)
    },
    // 查询市值占比图
    getMarketCapProportion(){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                }
            })
                
        } 
        const config = {
            method: "get",
            url: "DataAnalyse/category/multiFactorCategory/getMarketCapProportion"
        }
    
        return Request.request(config)
    },
    // // 查询github每日数据
    // queryGitHubDailyData(modle){
    //     // param
    //     // coinName = aave
    //     // coinType = ERC20
    //     // 判断是否开发环境
    //     if (process.env.NODE_ENV === development){
    //         return Promise.resolve({
    //             data:{
    //                 code:"success",
    //                 msg:"成功", 
    //                 data:{
    //                     gitInfo:[],
    //                     gitInfoSum:[],
    //                     priceInfo:[],
    //                     repositorys:[]
    //                 } 
    //             }
    //         })
                
    //     } 

    //     const config = {
    //         method: "get",
    //         url: "DataAnalyse/fundamental/queryGitHubDailyData",
    //         params: modle
    //     }
    
    //     return Request.request(config)
    // },
    // 查询github所有仓库数据
    queryGitHubRepositoriesInfo(modle){
        // coinName:币种的简称,必输
        // coinType:币种类型,必输
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功", 
                    data:{
                        // gitInfo:[],
                        // gitInfoSum:[],
                        // priceInfo:[],
                        repositories:[]
                    } 
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "DataAnalyse/tracker/fundamental/queryGitHubRepositoriesInfo",
            params: modle
        }
    
        return Request.request(config)
    },
    // 查询github每日数据
    queryGitHubDataInfo(modle){
        // coinName:币种的简称,必输
        // coinType:币种类型,必输
        // falconId
        // owner:github仓库的owner,必输
        // repositories:github仓库repository,必输 (ALL时将所有repository用英文逗号分隔传递）
        // countType:统计方式,必输（0：单日，1：累加）
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功", 
                    data:{
                        // gitInfo:[],
                        // gitInfoSum:[],
                        // priceInfo:[],
                        repositoryInfo:{},
                        gitInfo:[],
                        priceInfo:[]
                    } 
                }
            })
                
        } 

        const config = {
            method: "post",
            url: "DataAnalyse/tracker/fundamental/queryGitHubDataInfo",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询每日社区信息
    queryCommunityAndPriceData(modle){
        // param
        // coinName = aave
        // falconId = 18
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功", 
                    data:{
                        communityInfo:[],
                        priceInfo:[]
                    }
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/fundamental/queryCommunityAndPriceData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询每日价格信息
    queryPriceData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",  
                    data:{
                        priceInfo:[]
                    }
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/priceVolume/queryPriceData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询dex币对信息
    queryPairsData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        pairsInfo:[]
                    }
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/fundamental/queryPairsData",
            params: modle
        }
    
        return Request.request(config)
    },

    //查询dex交易统计
    queryDexTxnCountData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // pairAddress = 0x5ab53ee1d50eef2c1dd3d5402789cd27bb52c1bb
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        txnDistribution:[],
                        poolMobility:[]
                    }
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/priceVolume/queryDexTxnCountData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询dex大额交易
    queryDexBigTxnData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // pairAddress = 0x5ab53ee1d50eef2c1dd3d5402789cd27bb52c1bb
        // pageNum = 1
        // pageSize = 20
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        bigTxnInfo:[],
                        PageInfo:[]
                    }
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/priceVolume/queryDexBigTxnData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询链上基本信息
    queryOnChainBaseInfoData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        addressNumData:[],
                        priceInfo:[],
                        supplyData:[],
                        tvlData:{
                            abled:true,
                            tvl:[]
                        }
                    }
                }
            })
                
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryOnChainBaseInfoData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询链上基本信息
    queryOpenInterestData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        holderInfo:[],
                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/derivative/queryOpenInterestData",
            params: modle
        }
    
        return Request.request(config)
    },
    
    //查询72内爆仓统计数据
    queryLiquidationCount72HData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        bigTxnInfo:[],
                        PageInfo:[]
                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/derivative/queryLiquidationCount72HData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询爆仓详情
    queryLiquidationInfoData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // pageNum = 1
        // pageSize = 10
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        bigTxnInfo:[],
                        PageInfo:[]
                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/derivative/queryLiquidationInfoData",
            params: modle
        }
    
        return Request.request(config)
    },


    // 链上数据 - 大额交易数据 查询大额交易数据
    queryBigBalanceTransactionData(modle){
        // param
        // coinName = aave
        // coinType = ERC20
        // pageNum = 1
        // pageSize = 10
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{
                        bigTxnInfo:[],
                        PageInfo:[]
                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryBigBalanceTransactionData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 链上数据 - 持币地址详情
    // 查询币种TAG数据
    queryCoinTagData(modle){
        // param
        // coinName = aave
        // coinType = BTC_CLASS
        // orderBy = SECOND_TAG
        // orderType = asc
        // pageNum = 1
        // pageSize = 10
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryCoinTagData",
            params: modle
        }
    
        return Request.request(config)
    },


    // 查询余额和价格
    queryBalanceAndPriceByAddressData(modle){
        // param
        // coinName = aave
        // coinType = BTC_CLASS
        // address = 12NyfPNeERC3i5ZCnQL6Stg3ovtrA4Ke1z
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryBalanceAndPriceByAddressData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询地址交易详情
    queryTransactionInfoByAddress(modle){
        // param
        // coinName = aave
        // coinType = BTC_CLASS
        // pageNum = 1
        // pageSize = 30
        // isMonitorAddress = 1
        // address = 12NyfPNeERC3i5ZCnQL6Stg3ovtrA4Ke1z
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryTransactionInfoByAddress",
            params: modle
        }
    
        return Request.request(config)
    },
    // 链上数据的cex页面的表单数据
    queryOnChainExchangeBalanceByFalconId(modle){
        // coinName = aave
        // coinType = BTC_CLASS
        // falconId = 3
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryOnChainExchangeBalanceByFalconId",
            params: modle
        }
    
        return Request.request(config)
    },


    // 查询持币Top10000地址数据
    queryTop10000HolderData(modle){
        // param
        // coinName = aave
        // coinType = BTC_CLASS
        // pageNum = 1
        // pageSize = 30
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryTop10000HolderData",
            params: modle
        }
    
        return Request.request(config)
    },

    // // 查询余额地址数分布数据
    // queryDistributionData(modle){
    //     // param
    //     // coinName = aave
    //     // coinType = BTC_CLASS
    //     // 判断是否开发环境
    //     if (process.env.NODE_ENV === development){
    //         return Promise.resolve({
    //             data:{
    //                 code:"success",
    //                 msg:"成功",
    //                 data:{

    //                 }
    //             }
    //         })
    //     } 

    //     const config = {
    //         method: "get",
    //         url: "DataAnalyse/onChain/queryDistributionData",
    //         params: modle
    //     }
    
    //     return Request.request(config)
    // },
    // 查询余额地址数分布数据
    queryCoinDistributionData(modle){
        // param
        // coinName = aave
        // coinType = BTC_CLASS
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        } 

        const config = {
            method: "get",
            url: "DataAnalyse/tracker/onChain/queryCoinDistributionData",
            params: modle
        }
    
        return Request.request(config)
    },

    // 查询余额地址数分布数据
    queryCoinsMarkets(modle){
        // vs_currency=usd
        // order=market_cap_desc
        // price_change_percentage=24h
        // page=1
        // per_page=10
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "DataAnalyse/tracker/fundamental/coinsMarkets",
            params: modle
        }

        return Request.request(config)
    },

    // 查询余额地址数分布数据
    getAllFunctionClassAndModel(modle){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development){
            return Promise.resolve({
                data:{
                    code:"success",
                    msg:"成功",
                    data:{

                    }
                }
            })
        }

        const config = {
            method: "get",
            // url: "system/getAllFunctionClassAndModel",
            url: "DataAnalyse/tracker/system/getAllFunctionClassAndModel",
            params: modle
        }

        return Request.request(config)
    },

    // getAssetTrackerData(modle){
    //     // coinName=aave
    //     // coinType=ERC20
    //     // secondMenuId=C0001
    //     // 判断是否开发环境
    //     if (process.env.NODE_ENV === development){
    //         return Promise.resolve({
    //             data:{
    //                 code:"success",
    //                 msg:"成功",
    //                 data:{

    //                 }
    //             }
    //         })
    //     }

    //     const config = {
    //         method: "get",
    //         url: "DataAnalyse/overview/getAssetTrackerData",
    //         params: modle
    //     }

    //     return Request.request(config)
    // },
    getMostCategory() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getMostCategory",
            // params: modle
        }

        return Request.request(config)
    },
    getOverviewData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop500Date",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-24HPrice
    get24HPriceData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop50024hPrice",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-7DPrice
    get7DPriceData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop5007dPrice",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-30DPrice
    get30DPriceData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop50030dPrice",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-24HTvl
    get24HTvlData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop50024hTvl",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-7DTvl
    get7DTvlData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop5007dTvl",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-30DTvl
    get30DTvlData() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop50030dTvl",
            // params: modle
        }

        return Request.request(config)
    },
    // 板块概览-热门板块
    getHotCategories() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryTop500Hot",
            // params: modle
        }

        return Request.request(config)
    },
    
    // 获取热力图数据
    getTreeMapData(modle,that) {
        // type可选值：[
        // price(价格涨跌幅(均值))
        // marketCap(市值涨跌幅(均值))
        // priceVolume(价格涨跌幅(成交额加权)) ]

        // rank可选值 [
        // unlimited(不限)
        // GT500（前500) 
        // GT2000(前2000) ]

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getTreeMapData",
            params: modle,
            // 关键代码 cancelToken
    cancelToken: new CancelToken(function executor(c) {
        that.cancel = c
      })
        }

        return Request.request(config)
    },
    // 获取热力图中子板块详情数据
    getTreeMapDetailsData(modle,that) {
        // rank可选值 [
        // unlimited(不限)
        // GT500（前500) 
        // GT2000(前2000) ]

        // categoriesName:板块名称
        // dataSource 数据源
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getDetailsData",
            params: modle,
            // 关键代码 cancelToken
    cancelToken: new CancelToken(function executor(c) {
        that.cancel = c
      })
        }

        return Request.request(config)
    },
    // 获取板块统计的table数据
    getTableData(modle) {
        // type可选值：[
        // price(价格涨跌幅(均值))
        // marketCap(市值涨跌幅(均值))
        // priceVolume(价格涨跌幅(成交额加权)) ]

        // rank可选值 [
        // unlimited(不限)
        // GT500（前500) 
        // GT2000(前2000) ]

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getTableData",
            params: modle
        }

        return Request.request(config)
    },
    // 获取板块统计的table的表现最好最差的logo数据
    getCmcIdAndIndexMapping() {
        // rank可选值 [
            // unlimited(不限)
            // GT500（前500) 
            // GT2000(前2000) ]
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCmcIdAndIndexMapping",
        }

        return Request.request(config)
    },
    // 获取新的板块前三的数据
    getNewCategoryTop3() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getNewCategoryTop3",
        }

        return Request.request(config)
    },
    // 获取板块统计的收藏的数据
    queryCollectDto(modle) {
        // userId：用户id
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/categoryOperate/queryCollectDto",
            params: modle
        }

        return Request.request(config)
    },
    // 收藏板块
    categoryCollect(modle) {
        // userId：用户id
        // categoryId：板块id
        // categorySource：板块所在数据源
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "post",
            url: "/DataAnalyse/category/categoryOperate/collect",
            params: modle
        }

        return Request.request(config)
    },
    // 取消板块的收藏
    categoryCancelCollect(modle) {
        // userId：用户id
        // categoryId：板块id
        // categorySource：板块所在数据源
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "post",
            url: "/DataAnalyse/category/categoryOperate/cancelCollect",
            params: modle
        }

        return Request.request(config)
    },
    // 获取多因子选币的收藏的数据
    queryByCollect(modle) {
        /**
            keywords：字符串，用于模糊搜索
            page：第几页，默认1
            size: 每页几条，默认10
            cardId：返回策略列表时，定位回到原位的特殊用途
            uid：必填，用户id
         **/
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screenerCard/queryByCollect",
            params: modle
        }

        return Request.request(config)
    },
    // 收藏策略
    addCollect(modle) {
        // userId：用户id
        // cardId：策略id
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "post",
            url: "/DataAnalyse/selectCoin/screenerCard/addCollect",
            params: modle
        }

        return Request.request(config)
    },
    // 取消策略的收藏
    cancelCollect(modle) {
        // collectId:收藏id
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "delete",
            url: "/DataAnalyse/selectCoin/screenerCard/cancelCollect/"+modle.collectId,
            // params: modle
        }

        return Request.request(config)
    },
    // 查询该用户收藏的所有策略
    queryCollectMap(modle) {
        // userId：用户id
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screenerCard/queryCollectMap",
            params: modle
        }

        return Request.request(config)
    },
    // 查询该板块详情页面基本数据
    getCategoryDetailData(modle) {
        // categoryName：板块名称
        // dataSource：数据源
        // userId：用户id
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryDetailData",
            params: modle
        }

        return Request.request(config)
    },
    // 查询该板块详情页面进阶数据
    getCategoryDetailAdvancedData(modle) {
        // categoryName：板块名称
        // dataSource：数据源
        // rank：排名
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getBestAndWorstData",
            params: modle
        }

        return Request.request(config)
    },
    // 获取板块统计的category的详细table数据
    getTableCategoryData(modle) {
        // categoryName
        // rank可选值 [
            // unlimited(不限)
            // GT500（前500) 
            // GT2000(前2000) ]
        // dataSource可选值 [
            // falconSource
            // cmcSource
            // ]
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getTableCategoryData",
            params: modle
        }

        return Request.request(config)
    },
    getNewCategory() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getNewCategory",
            // params: modle
        }

        return Request.request(config)
    },
    getAllCategoryInSelector() {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getAllCategory",
            // params: modle
        }

        return Request.request(config)
    },
    getCategoryHistory(modle) {
        /**
         * category：下拉菜单的板块名称

        type可选值：[
        price(价格涨跌幅(均值))
        marketCap(市值涨跌幅(均值))
        priceVolume(价格涨跌幅(成交额加权)) ]

        rank可选值 [
        GT500（前500) GT2000(前200) ]
        
        dataSource可选值 [
        cmcSource 
        falconSource ]


         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryHistory",
            params: modle
        }

        return Request.request(config)
    },
    getCategoryHistoryExcess(modle) {
        /**
         * category：下拉菜单的板块名称

        type可选值：[
        price(价格涨跌幅(均值))
        marketCap(市值涨跌幅(均值))
        priceVolume(价格涨跌幅(成交额加权)) ]

        rank可选值 [
        unlimited(不限)
        GT500（前500) 
        GT2000(前200) 
        ]

         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryHistoryExcess",
            params: modle
        }

        return Request.request(config)
    },
    // 短线交易工具-数据更新时间
    getSpotTradingTopTime() {

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {
                        currentDateTime:"UTC+8 2023/03/15 22:10:00",
                        nextDateTime:"UTC+8 2023/03/15 22:15:00"
                
                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/shortTermTrading/shortTermTrading/getSpotTradingTopTime",
        }

        return Request.request(config)
    },
    // 短线交易工具-涨幅表现
    getSpotTradingPricePerformance(modle) {
        /**
        交易所名称
        exchange: Binance/OKX/Binance+OKX
        
        时间间隔
        timePeriod: 5M/15M/30M/1H

        涨幅表现
        performance: Best/Worst

        报价资产
        quote: unlimited/USDT/USDC/BUSD

         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/shortTermTrading/shortTermTrading/getSpotTradingPricePerformance",
            params: modle
        }

        return Request.request(config)
    },
    // 短线交易工具-多元表现
    getSpotTradingMultiplePerformance(modle) {
        /**
        交易所名称
        exchange: Binance/OKX/Binance,OKX
        
        时间间隔
        timePeriod: 5M/15M/30M/1H

        涨跌幅
        priceChange: None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        成交量变化
        volumeChange:None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        换手率
        turnoverRate:None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        市值排名
        marketCapRank:None/-∞~+∞/5~+∞/6~19 …


        报价资产
        quote: unlimited/USDT/USDC/BUSD

         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/shortTermTrading/shortTermTrading/getSpotTradingMultiplePerformance",
            params: modle
        }

        return Request.request(config)
    },
    // 短线交易工具-量价背驰
    getSpotTradingDivergenceVolumePrice(modle) {
        /**
        交易所名称
        exchange: Binance/OKX/Binance,OKX
        
        时间间隔
        timePeriod: 5M/15M/30M/1H

        涨跌幅
        priceChange: None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        买卖量占比
        makerBuy: None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        市值排名
        marketCapRank:None/-∞~+∞/5~+∞/6~19 …


        报价资产
        quote: unlimited/USDT/USDC/BUSD

         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/shortTermTrading/shortTermTrading/getSpotTradingDivergenceVolumePrice",
            params: modle
        }

        return Request.request(config)
    },
    // 短线交易工具-现货-大笔成交
    getSpotTradingLargeDeal(modle) {
        /**
        交易所名称及方向
        exchangeDirection: Binance_in/Binance_out/OKX_in/OKX_out/Binance_in,OKX_in
        
        时间间隔
        timePeriod: 5M/15M/30M/1H

        方向
        direction：sell/buy

        换手率
        turnoverRate:None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        金额
        volumeUSD:None/-∞~+∞/-0.5~+∞/0.6~19(0.6%~19%) …

        报价资产
        quote: unlimited/USDT/USDC/BUSD

         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/shortTermTrading/shortTermTrading/getSpotTradingLargeDeal",
            params: modle
        }

        return Request.request(config)
    },
    // alert-消息推送-新增推送消息
    insertMessage(modle) {
        /**
        userId：用户id
        messageName:消息名称
        function：所属功能
        messageContent：消息内容
        language：语言
        capital：资产
        notificationMethod：通知方式

         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/insertMessage",
            params: modle
        }

        return Request.request(config)
    },
    // alert-消息推送-删除推送消息
    deleteMessage(modle) {
        /**
        userId：用户id
        messageId：消息id
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/deleteMessage",
            params: modle
        }

        return Request.request(config)
    },
    // alert-消息推送-修改推送消息
    updateMessage(modle) {
        /**
        userId：用户id
        messageId：消息id
        messageName:消息名称
        function：所属功能
        messageContent：消息内容
        language：语言
        capital：资产
        notificationMethod：通知方式
        openNotificate: 开关 （1开0关）
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/updateMessage",
            params: modle
        }

        return Request.request(config)
    },
    // alert-消息推送-查询推送消息
    selectMessage(modle) {
        /**
        userId：用户id
        messageId：消息id（可以为空，为空则返回该用户下面所有的消息）
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/selectMessage",
            params: modle
        }

        return Request.request(config)
    },
    // 查询所有可选的messageContent
    selectMessageContent() {

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/selectMessageContent",
        }

        return Request.request(config)
    },
    // alert-消息推送-绑定telegram账号
    bindTelegram(modle) {
        /**
        tgId：telegram的id
        userId:用户id
        tgFirstName：telegram用户名
        tgLastName：telegram用户性
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/bindTelegram",
            params: modle
        }

        return Request.request(config)
    },
    // alert-消息推送-取消绑定telegram账号
    cancelBindTelegram(modle) {
        /**
        tgId：telegram的id
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/cancelBindTelegram",
            params: modle
        }

        return Request.request(config)
    },
    // alert-消息推送-查询该用户是否绑telegram账号
    isBindTelegram(modle) {
        /**
        userId:用户id
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/tracker/message/isBindTelegram",
            params: modle
        }

        return Request.request(config)
    },
    getAllCategory(modle){
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screen/getCategories",
            params: modle
        }
        return Request.request(config)
    },
    getCategoryDataCount(modle){
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screen/getCategoryCount",
            params: modle
        }
        return Request.request(config)
    },
    getL3Range(url,modle){
        const config = {
            method: "get",
            url: url,
            params: modle
        }
        return Request.request(config)
    },
    getResultCount(url,modle){
        const config = {
            method: "get",
            url: url,
            params: modle
        }
        return Request.request(config)
    },
    getAllCoin(modle){
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screen/getAllCoinInSelectCoin",
            params: modle
        }
        return Request.request(config)
    },
    getCoinInByCoinName(modle){
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screen/getCoinInByCoinName",
            params: modle
        }
        return Request.request(config)
    },
    getResultByFactory(modle){
        const config = {
            method: "POST",
            url: "/DataAnalyse/selectCoin/screen/getCoinDataByFactors",
            data: JSON.stringify(modle)
        }
        return Request.request(config)
    },
    getResultByCoin(modle){
        const config = {
            method: "POST",
            url: "/DataAnalyse/selectCoin/screen/getCoinDataByCmcIdAndCoingeckoId",
            data: JSON.stringify(modle)
        }
        return Request.request(config)
    },
    saveStrategy(modle){
        const config = {
            method: "POST",
            url: "/DataAnalyse/selectCoin/screenerCard/save",
            data: JSON.stringify(modle)
        }
        return Request.request(config)
    },
    delStrategy(modle){
        const config = {
            method: "DELETE",
            url: "/DataAnalyse/selectCoin/screenerCard/del/"+modle.cardId,
        }
        return Request.request(config)
    },
    editStrategy(modle){
        const config = {
            method: "PUT",
            url: "/DataAnalyse/selectCoin/screenerCard/edit",
            data: JSON.stringify(modle)
        }
        return Request.request(config)
    },
    getStrategyDetails(modle){
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screenerCard/queryById",
            params: modle
        }
        return Request.request(config)
    },
    getLogoByCmcIds(modle){
        const config = {
            method: "post",
            url: "/DataAnalyse/selectCoin/screen/getLogoByCmcIds",
            data: JSON.stringify(modle)
        }
        return Request.request(config)
    },
    // 多因子选币-查询“全部”列表的数据
    queryCurrencyAll(modle) {
        /**
            keywords：字符串，用于模糊搜索
            page：第几页，默认1
            size: 每页几条，默认10
            cardId：返回策略列表时，定位回到原位的特殊用途
            uid：无含义
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screenerCard/queryAll",
            params: modle
        }

        return Request.request(config)
    },
    // 多因子选币-查询“我的”列表的数据
    queryCurrencyMy(modle) {
        /**
            keywords：字符串，用于模糊搜索
            page：第几页，默认1
            size: 每页几条，默认10
            cardId：返回策略列表时，定位回到原位的特殊用途
            uid：必填
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screenerCard/queryByUid",
            params: modle
        }

        return Request.request(config)
    },
    // 多因子选币-查询“推荐”列表的数据
    queryCurrencyCommend(modle) {
        /**
            keywords：字符串，用于模糊搜索
            page：第几页，默认1
            size: 每页几条，默认10
            cardId：返回策略列表时，定位回到原位的特殊用途
            uid：无含义
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/selectCoin/screenerCard/queryByCommend",
            params: modle
        }

        return Request.request(config)
    },
    // 多因子选板块-k线图的数据
    getCategoriesKData(modle) {
        /**
            rank：市值排名(必填)unlimited, GT500, GT2000 对应 不限 前500名 前2000名
            categoryName：板块名(必填)
            dataSource（必传），目前可传：“falconSource“、“cmcSource“两种数据源
         **/

        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {

                    }
                }
            })
        }
        const config = {
            method: "get",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoriesKData",
            params: modle
        }

        return Request.request(config)
    },
    // 多因子选币 获取交易对数据
    getExchangeListByExchangeName(modle) {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {
                    }
                }
            })
        }
        const config = {
            method: "get",
            params: modle,
            url: "/DataAnalyse/selectCoin/screen/getExchangeListByExchangeName",
        }
        return Request.request(config)
    },
    // 查询当前币种支持的排名前五的交易所信息
    getExchangeInfoByCoinIndex(modle) {
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {
                    }
                }
            })
        }
        const config = {
            method: "POST",
            url: "/DataAnalyse/selectCoin/screen/getExchangeInfoByCoinIndex",
            data: modle
        }
        return Request.request(config)
    },
    // 筛选因子全部为交易所的结果集接口
    getCoinDataByExchangeId(modle){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {
                    }
                }
            })
        }
        const config = {
            method: "post",
            url: "/DataAnalyse/selectCoin/screen/getCoinDataByExchangeId",
            data: modle
        }
        return Request.request(config)
    },
    // 板块新闻
    getCategoryNews(modle){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {
                    }
                }
            })
        }
        const config = {
            method: "GET",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryNewsPage",
            params: modle
        }
        return Request.request(config)
    },
    getCategoryStatistic(modle){
        // 判断是否开发环境
        if (process.env.NODE_ENV === development) {
            return Promise.resolve({
                data: {
                    code: "success",
                    msg: "成功",
                    data: {
                    }
                }
            })
        }
        const config = {
            method: "GET",
            url: "/DataAnalyse/category/multiFactorCategory/getCategoryStatistic",
            params: modle
        }
        return Request.request(config)
    },
}
