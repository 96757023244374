<template>
  <div class="header-bar">
    <span class="logo">
      <img src="../../assets/icon/logo@2x.png" />
    </span>

    <div class="select">
      <el-popover placement="bottom-start" popper-class="myPopoverHome" :width="0" trigger="click">
        <template #reference>
          <span
            :class="[
              { checked: currentPlate == 'home' },
            ]"
            @click="toHome"
            >{{ zh_en === "en" ? "HOME" : "首页" }}</span
          >
        </template>
      </el-popover>
      <el-popover
        popper-class="myPopoverH"
        :offset="0"
        v-if="isHas('S0001')"
        placement="bottom-start"
        :width="360"
        trigger="hover"
        @show="handleShow('SELECT')"
        @before-leave="handleHide"
      >
        <template #reference>
          <span
            :class="[
              {
                checked:
                  currentPlate == 'selectPlate1' ||
                  currentPlate == 'selectPlate2',
              },
            ]"
            >{{ zh_en=='en'?"SELECT":"选" }}
            <span
              v-show="currentMenu == 'SELECT'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toOpen1.svg" alt="" />
            </span>
            <span
              v-show="currentMenu != 'SELECT'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toClose.svg" alt="" />
            </span>
          </span>
        </template>
        <el-col>
          <div
            v-if="isHas('A0002')"
            :class="[
              { item: currentPlate != 'selectPlate2' },
              { selected: currentPlate == 'selectPlate2' },
            ]"
            @click="toSelectTrack()"
          >
            <div class="aTitle">
              <img src="@/assets/icon/08.svg" alt="" />
              {{ zh_en=='en'?"Category Analyzer":"板块分析" }}
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en=='en'">
                <span>Discover&nbsp;</span>
                <span>promising&nbsp;</span>
                <span>category-based&nbsp;</span>
                <span>opportunities&nbsp;</span>
                <span>using&nbsp;</span>
                <span>multi-dimensional&nbsp;</span>
                <span>data&nbsp;</span>
                <span>and&nbsp;</span>
                <span>market&nbsp;</span>
                <span>factors&nbsp;</span>
              </span>
              <span v-show="zh_en!='en'">多元维度 布局板块</span>
            </div>
          </div>
          <div
            v-if="isHas('A0001')"
            :class="[
              { item1: currentPlate != 'selectPlate1' },
              { selected1: currentPlate == 'selectPlate1' },
            ]"
            @click="toSelectCurrency()"
          >
            <div class="aTitle" style="display: inline-flex">
              <img src="@/assets/icon/02.svg" alt="" />
              <div style="display: flex; align-items: center">
                <span>
                  {{
                    zh_en === "en"
                      ? "Multi-Factor Token Screener"
                      : "多因子选币"
                  }}
                </span>
                <div style="height: 30px">
                  <img
                    style="height: 40px; width: 40px; bottom: 10px"
                    src="@/assets/img/selectCurrency/beta.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en=='en'">
                <span>Sniff&nbsp;</span>
                <span>promising&nbsp;</span>
                <span>investment&nbsp;</span>
                <span>opportunities&nbsp;</span>
                <span>using&nbsp;</span>
                <span>multiple&nbsp;</span>
                <span>factors.</span>
              </span>
              <span v-show="zh_en!='en'">构建多元策略 洞悉投资机会</span>
            </div>
          </div>
        </el-col>
      </el-popover>

      <el-popover
        v-if="isHas('S0002')"
        placement="bottom-start"
        :width="360"
        trigger="hover"
        popper-class="myPopoverH"
        :offset="0"
        @show="handleShow('BUY')"
        @before-leave="handleHide"
      >
        <template #reference>
          <span
            :class="[
              {
                checked:
                  currentPlate == 'buyPlate1' || currentPlate == 'buyPlate2',
              },
            ]"
            
            >{{ zh_en === 'en'?"BUY":"买" }}
            <span
              v-show="currentMenu == 'BUY'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toOpen1.svg" alt="" />
            </span>
            <span
              v-show="currentMenu != 'BUY'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toClose.svg" alt="" />
            </span>
          </span>
        </template>
        <el-col>
          <div
            :style="{ cursor: 'not-allowed' }"
            v-if="isHas('A0004')"
            :class="[
              { selected: currentPlate == 'buyPlate1' },
              { item: currentPlate != 'buyPlate1' },
            ]"
          >
            <div class="aTitle">
              <img src="@/assets/icon/01.svg" alt="" />
              {{ zh_en === 'en'?"Short-Term Trading Tool":"短线交易工具" }}
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en === 'en'">
                <span>Identify&nbsp;</span>
                <span>Short-Term&nbsp;</span>
                <span>Trading&nbsp;</span>
                <span>Opportunity&nbsp;</span>
              </span>
              <span v-show="zh_en !== 'en'">快人一步 抢得先机</span>
            </div>
          </div>
          <div
            :style="{ cursor: 'not-allowed' }"
            v-if="isHas('A0005')"
            :class="[
              { selected1: currentPlate == 'buyPlate2' },
              { item1: currentPlate != 'buyPlate2' },
            ]"
          >
            <!-- @click="toMacroscopicInvestment()" -->
            <div class="aTitle">
              <img src="@/assets/icon/07.svg" alt="" />
              {{ zh_en === 'en'?"Macro Analyzer":"宏观分析" }}
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en === 'en'">
                <span>Analyze&nbsp;</span>
                <span>macroeconomic&nbsp;</span>
                <span>data&nbsp;</span>
                <span>to&nbsp;</span>
                <span>Identify&nbsp;</span>
                <span>long-term&nbsp;</span>
                <span>investment&nbsp;</span>
                <span>opportunities&nbsp;</span>
              </span>
              <span v-show="zh_en !== 'en'">高瞻远瞩 长线布局</span>
            </div>
          </div>
        </el-col>
      </el-popover>

      <el-popover
        v-if="isHas('S0003')"
        placement="bottom-start"
        :width="360"
        trigger="hover"
        popper-class="myPopoverH"
        :offset="0"
        @show="handleShow('MANAGEMENT')"
        @before-leave="handleHide"
      >
        <template #reference>
          <span
            :class="[{ checked: currentPlate == 'assetTracker' }]"
            
            >{{ zh_en === "en" ? "MANAGEMENT" : "管" }}
            <span
              v-show="currentMenu == 'MANAGEMENT'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toOpen1.svg" alt="" />
            </span>
            <span
              v-show="currentMenu != 'MANAGEMENT'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toClose.svg" alt="" />
            </span>
          </span>
        </template>
        <el-col>
          <div
            v-if="isHas('A0003')"
            @click="toManage()"
            :class="[
              { item: currentPlate != 'assetTracker' },
              { selected: currentPlate == 'assetTracker' },
            ]"
          >
            <div class="aTitle">
              <img src="@/assets/icon/04.svg" alt="" />{{ zh_en === "en"?"Asset Tracker":"资产追踪器" }}
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en === 'en'">
                <span>Keep&nbsp;</span>
                <span>track&nbsp;</span>
                <span>of&nbsp;</span>
                <span>your&nbsp;</span>
                <span>assets&nbsp;</span>
                <span>and&nbsp;</span>
                <span>never&nbsp;</span>
                <span>miss&nbsp;</span>
                <span>any&nbsp;</span>
                <span>vital&nbsp;</span>
                <span>data&nbsp;</span>
                <span>or&nbsp;</span>
                <span>information&nbsp;</span>
              </span>
              <span v-show="zh_en !== 'en'">追踪资产 不漏点滴</span>
            </div>
          </div>
        </el-col>
      </el-popover>

      <el-popover
        v-if="isHas('S0004')"
        placement="bottom-start"
        :width="360"
        trigger="hover"
        popper-class="myPopoverH"
        :offset="0"
        @show="handleShow('SELL')"
        @before-leave="handleHide"
      >
        <template #reference>
          <span
            :class="[{ checked: currentPlate == 'riskReminder' }]"
            
            >{{ zh_en === 'en'?"SELL":"卖" }}
            <span
              v-show="currentMenu == 'SELL'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toOpen1.svg" alt="" />
            </span>
            <span
              v-show="currentMenu != 'SELL'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toClose.svg" alt="" />
            </span>
          </span>
        </template>
        <el-col>
          <div
            :style="{ cursor: 'not-allowed' }"
            v-if="isHas('A0006')"
            :class="[
              { item: currentPlate != 'riskReminder' },
              { selected: currentPlate == 'riskReminder' },
            ]"
          >
            <!-- @click="toRiskReminder()" -->
            <div class="aTitle">
              <img src="@/assets/icon/05.svg" alt="" />{{ zh_en === 'en'?"Risk Alert":"风险提示器" }}
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en === 'en'">
                <span>Risk&nbsp;</span>
                <span>AlertRecognize&nbsp;</span>
                <span>risks&nbsp;</span>
                <span>before&nbsp;</span>
                <span>the&nbsp;</span>
                <span>market&nbsp;</span>
              </span>
              <span v-show="zh_en !== 'en'">先见风险 未雨绸缪</span>
            </div>
          </div>
        </el-col>
      </el-popover>

      <el-popover
        placement="bottom-start"
        :width="360"
        trigger="hover"
        popper-class="myPopoverH"
        :offset="0"
        @show="handleShow('TOOLS')"
        @before-leave="handleHide"
      >
        <template #reference>
          <span
            :class="[{ checked: currentPlate == 'falconBot' }]"
            
            >{{ zh_en=='en'?"TOOLS":"工具" }}
            <span
              v-show="currentMenu == 'TOOLS'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toOpen1.svg" alt="" />
            </span>
            <span
              v-show="currentMenu != 'TOOLS'"
              style="display: inline-block; width: 24px; height: 21px"
            >
              <img src="@/assets/icon/toClose.svg" alt="" />
            </span>
          </span>
        </template>
        <el-col>
          <div
            :class="[
              { item: currentPlate != 'falconBot' },
              { selected: currentPlate == 'falconBot' },
            ]"
            @click="toFalconBot"
          >
            <div class="aTitle">
              <img src="@/assets/icon/06.svg" alt="" /><span
                style="padding-right: 10px"
                >{{ "Falcon Bot" }}</span
              ><img class="link" src="@/assets/icon/10.svg" alt="" />
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en=='en'">
                <span>Sniff&nbsp;</span>
                <span>trading&nbsp;</span>
                <span>signal&nbsp;</span>
                <span>and&nbsp;</span>
                <span>track&nbsp;</span>
                <span>token&nbsp;</span>
                <span>data&nbsp;</span>
                <span>in&nbsp;</span>
                <span>this&nbsp;</span>
                <span>telegram&nbsp;</span>
                <span>bot.&nbsp;</span>
              </span>
              <span v-show="zh_en!='en'">捕捉交易信号 追踪币种数据</span>
            </div>
          </div>
          <div
            :class="[
              { item1: currentPlate != 'falconNews' },
              { selected1: currentPlate == 'falconNews' },
            ]"
            @click="toFalconChannel"
          >
            <div class="aTitle">
              <img src="@/assets/icon/01.svg" alt="" /><span
                style="padding-right: 10px"
                >{{ "Falcon News" }}</span
              ><img class="link" src="@/assets/icon/10.svg" alt="" />
            </div>
            <div class="subtitle">
              <span style="display: flex; flex-flow: row wrap" v-show="zh_en=='en'">
                <span>Capture&nbsp;</span>
                <span>the&nbsp;</span>
                <span>fastest&nbsp;</span>
                <span>crypto&nbsp;</span>
                <span>news&nbsp;</span>
                <span>and&nbsp;</span>
                <span>research&nbsp;</span>
                <span>report&nbsp;</span>
                <span>in&nbsp;</span>
                <span>this&nbsp;</span>
                <span>telegram&nbsp;</span>
                <span>channel.&nbsp;</span>
              </span>
              <span v-show="zh_en!='en'">最快的加密资产新闻 深度的行业研究报告</span>
            </div>
          </div>
        </el-col>
      </el-popover>
    </div>

    <div class="zh-en">
      <span @click="change_zh_en">
        <span :class="zh_en === 'en' ? 'active' : 'unActive'">{{
          zh_en === "en" ? "EN" : "英"
        }}</span>
        |
        <span :class="zh_en === 'ch' ? 'active' : ''">{{
          zh_en === "en" ? "CN" : "中"
        }}</span>
      </span>
    </div>
    <div class="loginOrRegister" v-show="!$route.query || !$route.query.uid">
      <el-button
        @click="toRegister"
        class="register"
        :class="[{ switchLR: name !== 'register',currentButton:name ==='register' }]"
        plain
        >{{ zh_en === "en" ? "Sign up" : "注册" }}</el-button
      >
      <el-button
        @click="toLogin"
        class="login"
        :class="[{ switchLR: name !== 'login',currentButton:name ==='login' }]"
        plain
        >{{ zh_en === "en" ? "Log in" : "登录" }}</el-button
      >
    </div>
    <div class="userHeader" v-show="$route.query && $route.query.uid">
      <el-popover
        placement="bottom"
        :width="zh_en === 'en' ? 300 : 260"
        trigger="click"
        :popper-class="isHide ? 'hide' : 'defHeader'"
        :offset="0"
        :hide-after="isHide ? 0 : 200"
      >
        <template #reference>
          <img
            class="defaultHeader"
            src="@/assets/img/login/defaultHeader.png"
            alt=""
          />
        </template>
        <el-col class="col">
          <div class="personalSetting" @click="toPersonal()">
            <!-- <div class="news"> -->
            <div style="display: inline-flex; align-items: center">
              <div class="hoverIcon">
                <img
                  src="@/assets/img/login/settings.svg"
                  alt=""
                  class="settings"
                />
              </div>
              <span>{{ zh_en == "en" ? "Personal Setting" : "个人设置" }}</span>
            </div>
            <img
              style="width: 24px; height: 24px; transform: rotate(270deg)"
              src="@/assets/img/login/gengduo.svg"
              alt=""
            />
          </div>
          <div class="news" @click="toMessage()">
            <!-- <div class="news"> -->
            <div style="display: inline-flex; align-items: center">
              <div class="hoverIcon">
                <div class="iconDiv">
                  <img
                    src="@/assets/img/login/hoverIcon.svg"
                    alt=""
                    class="iconIMG"
                  />
                </div>
                <div class="pointDiv">
                  <img
                    src="@/assets/img/login/point.svg"
                    alt=""
                    class="pointIMG"
                  />
                </div>
              </div>
              <span>{{
                zh_en == "en" ? "Message Management" : "消息管理"
              }}</span>
            </div>
            <img
              style="width: 24px; height: 24px; transform: rotate(270deg)"
              src="@/assets/img/login/gengduo.svg"
              alt=""
            />
          </div>
          <div class="logOut">
            <!-- <el-link :underline="false">Log out</el-link> -->
            <el-button @click="logout" color="#5F82E6" class="loginButton">
              <span class="loginOut">
                {{ $route.params.zh_en == "en" ? "Log out" : "退出" }}
              </span>
            </el-button>
          </div>
        </el-col>
      </el-popover>
    </div>
  </div>
</template>


<script>
import { ElMessage } from "element-plus";
import merge from "webpack-merge";
import CircularJSON from "circular-json";
const _ = require("lodash");
export default {
  name: "HeaderBar",
  props: ["is_login_page", "zh_en", "name"],
  data() {
    return {
      menus: [],
      currentPlate: "",
      currentMenu: "",
      nickname: "",
      aboriginalNickname: "",
      currentPlateName: "",
      isHide: false,
      email: "",
      uid: "",
      // isLogin:false
    };
  },
  watch: {
    $route: {
      handler(newMenu, oldMenu) {
        if (
          newMenu.name == "selectTrack" ||
          newMenu.name === "selectTrackDetail"
        ) {
          // 多因子选板块
          this.currentPlate = "selectPlate2";
        } else if (newMenu.name == "home" || newMenu.name === "menu") {
          // 资产追踪器
          this.currentPlate = "assetTracker";
        } else if (newMenu.name == "shortTermTradingTool") {
          // 短线交易工具
          this.currentPlate = "buyPlate1";
        } else if (newMenu.name == "macroscopicInvestment") {
          // 宏观投资分析工具
          this.currentPlate = "buyPlate2";
        } else if (
          newMenu.name == "selectCurrency" ||
          newMenu.name == "strategyDetails"
        ) {
          // 多因子选币
          this.currentPlate = "selectPlate1";
        } else if (newMenu.name == "riskReminder") {
          // 风险提示器
          this.currentPlate = "riskReminder";
        } else {
          this.currentPlate = "home";
        }
        if (newMenu.query && newMenu.query.uid) {
          this.uid = newMenu.query.uid;
          this.isHide = false;
          // this.getUserInfo();
        } else {
          this.uid = "";
          this.isHide = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    console.log("HeaderBar");
    const a = this.$route.name;
    this.currentPlateName = a;
  },
  methods: {
    getUserInfo() {
      this.$requests.getUserInfo({ UID: this.$route.query.uid }).then((res) => {
        if (res.data.code == "success") {
          this.uid = res.data.data.uid;
          this.email = res.data.data.email;
          this.nickname = res.data.data.user_name;
        }
      });
    },
    logout() {
      console.log("退出：：", this.$route);
      this.$requests.logout().then((res) => {
        if (res.data.result == "True") {
          ElMessage({
            type: "success",
            message:
              this.$route.params.zh_en == "en"
                ? "Exit successfully."
                : "退出成功！",
          });
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() - 1);
          document.cookie = "uid=; path=/;max-age=1;";
          // document.cookie = 'uid='
          //     // this.$router.replace({
          //     //   name: this.$route.name,
          //     //   params: this.$route.params,
          //     //   query: {
          //     //     uid: "",
          //     //   },
          //     // });
          if (this.$route.name == "menu") {
            // var params = this.$route.params
            // params.type = 'overview'
            this.$router.push({
              name: "menu",
              params: merge(this.$route.params, { type: "overview" }),
              query: merge(this.$route.query, { uid: "" }),
            });
            sessionStorage.removeItem("userInfo");
          } else if (this.$route.name == "messageManagement") {
            this.$nextTick(() => {
              sessionStorage.removeItem("userInfo");
            });
            const a = JSON.parse(sessionStorage.getItem("fromRoute"));
            console.log("fromRoute:", a);
            this.$router.push({
              name: a.name,
              params: a.params,
              query: merge(a.query, { uid: "" }),
            });
          } else if (this.$route.name == "personalCenter") {
            this.$router.push({
              name: "main",
              params: { zh_en: this.$route.params.zh_en },
              query: merge(this.$route.query, { uid: "" }),
            });
            sessionStorage.removeItem("userInfo");
          } else {
            this.$router.push({
              query: merge(this.$route.query, { uid: "" }),
            });
            sessionStorage.removeItem("userInfo");
          }
        } else {
          ElMessage({
            type: "warning",
            message:
              this.$route.params.zh_en == "en" ? "Exit failed." : "退出失败！",
          });
        }
      });
    },

    toLogin() {
      if (this.$route.name == "selectTrackDetail") {
        this.$router.push({
          name: "login",
          params: {
            zh_en: this.zh_en === "en" ? "en" : "ch",
          },
          query: {
            from: this.$route.meta.type,
            categoryName: this.$route.params.categoryName
              ? this.$route.params.categoryName
              : "",
            dataSource: this.$route.params.dataSource
              ? this.$route.params.dataSource
              : "",
            menu: this.$route.query.menu ? this.$route.query.menu : "tokenInfo",
            channel: this.$route.query.channel ? this.$route.query.channel : "",
            num: this.$route.query.num ? this.$route.query.num : "",
          },
        });
      } else {
        this.$router.push({
          name: "login",
          params: {
            zh_en: this.zh_en === "en" ? "en" : "ch",
          },
          query: {
            from: this.$route.meta.type,
            coin: this.$route.params.coin ? this.$route.params.coin : "",
            type: this.$route.params.type ? this.$route.params.type : "",
            channel: this.$route.query.channel ? this.$route.query.channel : "",
            num: this.$route.query.num ? this.$route.query.num : "",
          },
        });
      }

      // }
    },
    toRegister() {
      if (this.name != "register") {
        if (this.$route.name == "selectTrackDetail") {
          this.$router.push({
            name: "register",
            params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
            query: {
              from: this.$route.meta.type,
              categoryName: this.$route.params.categoryName
                ? this.$route.params.categoryName
                : "",
              dataSource: this.$route.params.dataSource
                ? this.$route.params.dataSource
                : "",
              menu: this.$route.query.menu
                ? this.$route.query.menu
                : "tokenInfo",
              // coin: this.$route.params.coin ? this.$route.params.coin : "",
              // type: this.$route.params.type ? this.$route.params.type : "",
              channel: this.$route.query.channel
                ? this.$route.query.channel
                : "",
              num: this.$route.query.num ? this.$route.query.num : "",
            },
          });
        } else {
          this.$router.push({
            name: "register",
            params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
            query: {
              from: this.$route.meta.type,
              //   categoryName: this.$route.params.categoryName?this.$route.params.categoryName:'',
              // dataSource: this.$route.params.dataSource?this.$route.params.dataSource:'',
              coin: this.$route.params.coin ? this.$route.params.coin : "",
              type: this.$route.params.type ? this.$route.params.type : "",
              channel: this.$route.query.channel
                ? this.$route.query.channel
                : "",
              num: this.$route.query.num ? this.$route.query.num : "",
            },
          });
        }
      }
    },
    toHome() {
      this.$router.push({
        name: this.zh_en == "en" ? "n1" : "n2",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toSelectCurrency() {
      this.$router.push({
        name: "selectCurrency",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toManage() {
      this.$router.push({
        name: "home",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toSelectTrack() {
      this.$router.push({
        name: "selectTrack",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          menu: "category_overview",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toShortTermTool() {
      this.$router.push({
        name: "shortTermTradingTool",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          menu: "home",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toMacroscopicInvestment() {
      this.$router.push({
        name: "macroscopicInvestment",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch", tab: "home" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toRiskReminder() {
      this.$router.push({
        name: "riskReminder",
        params: { zh_en: this.zh_en === "en" ? "en" : "ch", tab: "home" },
        query: {
          uid: this.$route.query.uid ? this.$route.query.uid : "",
          channel: this.$route.query.channel ? this.$route.query.channel : "",
          num: this.$route.query.num ? this.$route.query.num : "",
        },
      });
    },
    toFalconBot(event) {
      event.preventDefault(); // 阻止默认行为，避免链接的默认跳转
      window.open("https://t.me/falconbylucida_bot", "_blank");
    },
    toFalconChannel(event) {
      event.preventDefault(); // 阻止默认行为，避免链接的默认跳转
      window.open("https://t.me/falconnewsbylucida", "_blank");
    },
    toPersonal() {
      if (this.$route.name != "personalCenter") {
        // let route = {};
        // route.name = _.cloneDeep(this.$route.name);
        // route.params = _.cloneDeep(this.$route.params);
        // route.query = _.cloneDeep(this.$route.query);
        // sessionStorage.setItem("fromRoute", JSON.stringify(route));
        this.$router.push({
          name: "personalCenter",
          params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
          query: {
            uid: this.$route.query.uid ? this.$route.query.uid : "",
            channel: this.$route.query.channel ? this.$route.query.channel : "",
            num: this.$route.query.num ? this.$route.query.num : "",
          },
        });
      }
    },
    toMessage() {
      if (this.$route.name != "messageManagement") {
        let route = {};
        route.name = _.cloneDeep(this.$route.name);
        route.params = _.cloneDeep(this.$route.params);
        route.query = _.cloneDeep(this.$route.query);
        // sessionStorage.setItem("fromRoute",CircularJSON.stringify(this.$route))
        sessionStorage.setItem("fromRoute", JSON.stringify(route));
        this.$router.push({
          name: "messageManagement",
          params: { zh_en: this.zh_en === "en" ? "en" : "ch" },
          query: {
            uid: this.$route.query.uid ? this.$route.query.uid : "",
            channel: this.$route.query.channel ? this.$route.query.channel : "",
            num: this.$route.query.num ? this.$route.query.num : "",
          },
        });
      }
    },
    isHas(id) {
      return this.menus.indexOf(id) >= 0;
    },
    change_zh_en() {
      let p = this.$route.params;
      let q = this.$route.query;
      this.$router.push({
        name: this.$route.name,
        params: Object.assign(
          { ...this.$route.params },
          { zh_en: this.zh_en !== "ch" ? "ch" : "en" }
        ),
        query: Object.assign({ ...this.$route.query }),
      });
    },
    handleShow(name) {
      this.currentMenu = "";
      this.currentMenu = name;
    },
    handleHide() {
      this.currentMenu = "";
    },
  },

  created() {
    if (window.innerWidth > 750) {
      this.$requests.getAllFunctionClassAndModel().then(({ data }) => {
        let { functionClass, functionModel } = data.data;
        let self = this;
        functionClass.map((item) => {
          self.menus.push(item.id);
        });
        functionModel.map((item) => {
          self.menus.push(item.id);
        });
      });
    }
  },
};
</script>

<style>
.el-popover.myPopover {
  /* background-color: #036CFC; */
}
.el-popover.defHeader {
  background-color: #ffffff;
}
.el-popover.hide {
  display: none;
}

.el-popover .el-popper__arrow {
  display: none;
}
.el-popover.defHeader .el-popper__arrow {
  display: block;
  /* left: 60px !important; */
}
.el-popover.el-popper {
  border-radius: 0 !important;
  border: 0;
}
.myPopoverHome.el-popover.el-popper{
  display:none !important;
}
.el-popover.defHeader.el-popper {
  border-radius: 10px !important;
  /* border: 0; */
}
.myPopoverH.el-popover {
  /* inset:0 auto auto 94px !important; */
  --el-popover-padding:0;
  border-radius: 0 !important;
}
.el-popover.myPopoverH {
  /* inset: 0 auto auto 110px !important; */
  /* --el-popover-padding: 0;
  border-radius: 0 !important; */
}
/* .el-popover.myPopoverh {
  inset: 0 auto auto 135px !important;
  --el-popover-padding: 0;
  border-radius: 0 !important;
} */
/* .el-popover.myPopover1 {
  inset: 0 auto auto 83px !important;
  --el-popover-padding: 0;
  border-radius: 0 !important;
}
.el-popover.myPopover2 {
  inset: 0 auto auto 99px !important;
  --el-popover-padding: 0;
  border-radius: 0 !important;
} */
.el-popover.defHeader {
  /* inset: 0 auto auto -60px !important; */
  --el-popover-padding: 0;
  /* border-radius: 0 !important; */
}

.el-header {
  padding: 0;
  height: 50px;
}
</style>

<style lang="scss" scoped>
.overflow {
  display: none !important;
}
.checked {
  // background: #036cfc !important;
  border-bottom: 4px solid #4767b5 !important;
}
.userHeader {
  position: relative;
  .defaultHeader {
    position: absolute;
    top: 5px;
    width: 40px;
    cursor: pointer;
  }
}
.col {
  padding: 16px 20px;
}
.nickname {
  display: flex;
  justify-content: space-between;
}
.ok {
  width: 30px;
  cursor: pointer;
}
.uid {
  margin-top: 10px;
  color: #272b38;
  font-family: "Outfit-Regular";
  font-size: 14px;
  font-weight: 400;
}
.mail {
  color: #272b38;
  font-family: "Outfit-Regular";
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}
.personalSetting {
  width: 100%;
  cursor: pointer;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  font-family: "Outfit-Regular";
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background: #ededed;
  border-radius: 8px;
  margin-bottom: 10px;
  .hoverIcon {
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50% 50%;
    background-color: #fff;
    .settings {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
    }
  }
}
.news {
  width: 100%;
  cursor: pointer;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  font-family: "Outfit-Regular";
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background: #ededed;
  border-radius: 8px;
  .hoverIcon {
    margin: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50% 50%;
    background-color: #fff;
    .settings {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
    }
    .iconDiv {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 30px;
      height: 30px;
      text-indent: -30px;
      overflow: hidden;
      .iconIMG {
        width: 30px;
        height: 30px;
        filter: drop-shadow(30px 0 0 #036cfc);
      }
    }
    .pointDiv {
      position: absolute;
      top: -1px;
      right: -1px;
      width: 23px;
      height: 23px;
      text-indent: -23px;
      overflow: hidden;
      .pointIMG {
        width: 23px;
        height: 23px;
        filter: drop-shadow(23px 0 0 #ff2a66);
      }
    }
  }
}
.logOut {
  margin-top: 10px;
  .loginButton {
    width: 100%;
    height: 38px;
  }
}
.loginButton.el-button {
  --el-button-hover-bg-color: #5f82e6 !important;
  --el-button-text-color: #ffffff;
  --el-color-black: #ffffff;
  --el-font-size-base: 16px;
  --el-font-weight-primary: bold;
  --el-border-radius-base: 0;
}
.active {
  color: #333333 !important;
}
.unActive {
  color: #999999 !important;
}
.selected {
  // color: #194e92 !important;
  // font-weight: bold !important;
  padding-top: 20px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 20px;
  font-weight: 400;
  cursor: pointer !important;
  border-radius: 4px;
  text-align: left !important;
  .aTitle {
    font-family: "Outfit-Bold";
    background-color: #ededed;
    border-radius: 8px 8px 0 0;
    width: 100%;
    padding: 10px 10px 6px 10px; 
    font-size: 16px;
    color: #333333;
    // height: 40px;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 10px;
      width: 30px;
    }
    .link {
      width: 18px;
    }
  }
  .subtitle {
    font-family: "Outfit-Regular";
    border-radius: 0 0 8px 8px;
    font-size: 14px;
    background-color: #ededed;
    color: #666666;
    padding-bottom: 13px;
    // padding-top: 13px;
    padding-left: 50px;
  }
}
.selected1 {
  // color: #194e92 !important;
  // font-weight: bold !important;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 20px;
  font-weight: 400;
  cursor: pointer !important;
  border-radius: 4px;
  text-align: left !important;
  .aTitle {
    font-family: "Outfit-Bold";
    border-radius: 8px 8px 0 0;
    background-color: #ededed;
    font-size: 16px;
    padding: 10px 10px 6px 10px;
    color: #333333;
    width: 100%;
    // height: 40px;
    display: inline-flex;
    align-items: center;
    img {
      margin-right: 10px;
      width: 30px;
    }
    .link {
      width: 18px;
    }
  }
  .subtitle {
    font-family: "Outfit-Regular";
    border-radius: 0 0 8px 8px;
    font-size: 14px;
    background-color: #ededed;
    color: #666666;
    padding-bottom: 13px;
    // padding-top: 13px;
    padding-left: 50px;
  }
}
.item {
  // color: #333333;
  padding-top: 20px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;

  font-size: 16px;
  background-color: #ffffff;
  font-weight: 400;
  width: 100%;
  height: 100%;
  .aTitle {
    font-family: "Outfit-Bold";
    border-radius: 8px 8px 0 0;
    width: 100%;
    color: #333333;
    padding: 10px 10px 6px 10px;
    display: inline-flex;
    align-items: center;
    // height: 40px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    .link {
      width: 18px !important;
    }
  }
  &:hover {
    .aTitle {
      background-color: #ededed;
    }
    // background-color: rgba(0, 0, 0, 0.05) !important;
    // color: #333333 !important;
    .subtitle {
      background-color: #ededed;
      // background-color: rgba(0, 0, 0, 0.05) !important;
      // color: #333333 !important;
    }
  }
  .subtitle {
    font-family: "Outfit-Regular";
    color: #666666;
    font-size: 14px;
    border-radius: 0 0 8px 8px;
    padding-bottom: 13px;
    // padding-top: 13px;
    padding-left: 50px;
  }

  &::selection {
    // color: #194e92 !important;
  }
}
.item.el-button {
  --el-button-disabled-bg-color: transparent !important;
  --el-button-disabled-border-color: transparent !important;
  justify-content: left;
  padding-left: 0;
}
.item1 {
  // color: #333333;
  // padding-top: 20px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;
  font-size: 16px;
  background-color: #ffffff;
  font-weight: 400;
  width: 100%;
  height: 100%;
  .aTitle {
    font-family: "Outfit-Bold";
    border-radius: 8px 8px 0 0;
    padding: 10px 10px 6px 10px;
    width: 100%;
    color: #333333;
    display: inline-flex;
    align-items: center;
    // height: 40px;
    img {
      margin-right: 10px;
      width: 30px;
    }
    .link {
      width: 18px;
    }
  }
  &:hover {
    .aTitle {
      background-color: #ededed;
    }
    // background-color: rgba(0, 0, 0, 0.05) !important;
    // color: #333333 !important;
    .subtitle {
      background-color: #ededed;
      // background-color: rgba(0, 0, 0, 0.05) !important;
      // color: #333333 !important;
    }
  }
  .subtitle {
    font-family: "Outfit-Regular";
    color: #666666;
    font-size: 14px;
    border-radius: 0 0 8px 8px;
    padding-bottom: 13px;
    // padding-top: 13px;
    padding-left: 50px;
  }

  &::selection {
    // color: #194e92 !important;
  }
}
.item1.el-button {
  --el-button-disabled-bg-color: transparent !important;
  --el-button-disabled-border-color: transparent !important;
  justify-content: left;
  padding-left: 0;
}

.header-bar {
  display: flex;
  flex-direction: row;
  width: 100%;

  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0 calc((100% - 1600px) / 2);
  height: 50px;
  background-color: #ededed;
  .select {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    > * {
      width: 20%;
      color: #333333;
      font-size: 14px;
      font-family: "Outfit-Medium";
      height: calc(100%);
      line-height: 50px;
      text-align: center;
      cursor: pointer;
      // border-top: 6px solid #3b3b3b;
      box-sizing: border-box;

      &:hover {
        // background-color: #036cfc;
        // color: #ffffff;
        border-bottom: 4px solid #4767b5;
      }
    }
    :nth-child(3) {
      // cursor: not-allowed;
    }
    :nth-child(5) {
      // cursor: not-allowed;
    }
  }

  .zh-en {
    display: flex;
    justify-content: right;
    flex: 1;
    
    width: 4%;
    text-align: center;
    color: #333333;
    font-family: Outfit-Medium;
    padding-right: 12px;
    font-size: 14px;
    span {
      cursor: pointer;
      span{
        color: #999999;
      }
    }
  }
  .loginOrRegister,
  .userHeader {
    width: 12%;
    text-align: center;
    height: 50px;
    line-height: 45px;
  }
}

.logo {
  width: 129px;
  height: 100%;
  display: flex;
  align-items: center;
  > img {
    width: 100%;
    height: 34px;
  }
}
.loginOut{
  font-size:16px;
  font-weight:400;
  font-family: "Outfit-Medium";
}
.login {
  width: 88px;
  color: #666;
  font-family: "Outfit-Medium";
  font: {
    size: 14px;
    weight: 400;
  }
}

.register {
  color: #666;
  width: 88px;
  font-family: Outfit-Medium;
  font: {
    size: 14px;
    weight: 400;
  }
}
.currentButton{
  color: #fff !important;
}
.switchLR {
  background-color: transparent;
}
.loginOrRegister .el-button {
  --el-button-bg-color: #5f82e6 !important;
  --el-button-hover-bg-color: #5f82e6 !important;
  height:28px !important;
}
:deep.el-popper-container-685 .is-dark {
  background-color: #000000 !important;
}
</style>
