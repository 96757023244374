import { createApp } from 'vue'
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import style2js from "./utils/style2js"
import request from './utils/requests'
import './assets/main.css'
import './assets/css/global.scss'
import './assets/Fonts-Outfit/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import HighchartsVue from "highcharts-vue"
import baiduAnalytics from 'vue-baidu-analytics'



const app = createApp(App);

app.config.globalProperties.$style2js = style2js;
app.config.globalProperties.$requests = request;
app.config.globalProperties.$localData = {
    save(key,value){
      window.sessionStorage[key] = JSON.stringify(value);
    },
    get(key){
      
      if(key === "search_key"){
          
          if(!window.sessionStorage[key]){

            ElMessage({
              type: 'error',
              message: "请重新到搜索栏搜索"
            })
            window.location.href = "/"
            return null
          }
      }
      return JSON.parse(window.sessionStorage[key] === undefined ? null : window.sessionStorage[key]);
    },
    remove(key){
      delete window.sessionStorage[key];
    },
    clear(){
      window.sessionStorage.clear()
    }
}


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(HighchartsVue)
app.use(router)

app.use(baiduAnalytics, {
  router: router,
  siteIdList: [
    'cae31fbc1d5db1632d57dc6eafa4626c'
  ],
  isDebug: false
})

app.use(ElementPlus)

app.mount('#app')
