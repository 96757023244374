import CryptoJS from "crypto-js";
// const CryptoJS = require('crypto-js');

// const MODE = 'AES/ECB/PKCS5Padding';
// const KEY_ALGORITHM = 'AES';
// const CHARSET = 'utf-8';
// const KEY_SIZE = 128;

// 生成密钥
export default {
	getKey() {
		const key = CryptoJS.lib.WordArray.random(KEY_SIZE / 8); // 生成128位密钥
		return key;
	},

	// 加密
	encrypt(content, key) {
		const cipher = CryptoJS.AES.encrypt(content, key, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		const encrypted = cipher.ciphertext.toString(CryptoJS.enc.Base64);
		return encrypted;
	},

	// 解密
	// decrypt(content, key) {
	//   const decrypted = CryptoJS.AES.decrypt(content, key, {
	// 	mode: CryptoJS.mode.ECB,
	// 	padding: CryptoJS.pad.Pkcs7
	//   });
	//   const plaintext = CryptoJS.enc.Utf8.stringify(decrypted);
	//   return plaintext;
	// }
	decrypt(content, key) {
		// console.log(content)
		// console.log(key)
		var keyBytes = CryptoJS.enc.Utf8.parse(key);
		  var encryptedBytes = CryptoJS.enc.Base64.parse(content);
		  var decryptedBytes = CryptoJS.AES.decrypt(
		    { ciphertext: encryptedBytes },
		    keyBytes,
		    { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
		  );
		  var decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
		  // console.log(decryptedText)
		  return decryptedText;
	}
	// decrypt(encryptedData, key) {
	//   // 对 Base64 编码进行解码
	//   var decodedData = atob(encryptedData);

	//   // 新建Cipher 类
	//   var cipher = CryptoJS.algo.AES.createDecipher(CryptoJS.algo.AES, key);

	//   // 进行解密
	//   var decrypted = cipher.process(decodedData);
	//   decrypted.concat(cipher.finalize());

	//   // 转成字符串返回
	//   return decrypted.toString(CryptoJS.enc.Utf8);
	// }
}